<kt-portlet>
	<kt-portlet-header [sticky]="true" [title]="'Admission Enquiry List'" [class]="'card-head-lg'"
		[viewLoading$]="dataSource.loading$">
		<ng-container ktPortletTools>
			<button (click)="addAddmissionEnquiry()" mat-raised-button color="primary"
				matTooltip="Create new enquiry">New
				Enquiry</button>
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
			<div class="form-filtration">
			
				<div class="col-md-4 kt-margin-bottom-10-mobile">
					<mat-form-field>
						<input matInput placeholder="Search product" #searchInput placeholder="Search"
							class="mat-form-field mat-form-field-fluid">
						<mat-hint align="start">
							<strong>Search in all fields</strong>
						</mat-hint>
					</mat-form-field>
				</div>
			</div>
		</div>

		

		<div class="mat-table-wrapper">
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="id" matSortDirection="desc" matSortDisableClear>
				

				<!-- <ng-container matColumnDef="id">
					<mat-header-cell class="w-25" *matHeaderCellDef  >Id
					</mat-header-cell>
					<mat-cell *matCellDef="let enquiry;let i=index">{{i+1}}</mat-cell>
				</ng-container> -->
				<ng-container matColumnDef="id">
					<mat-header-cell class="w-25" *matHeaderCellDef  >Sr.No
					</mat-header-cell>
					<mat-cell *matCellDef="let enquiry;let i=index">{{((paginator.pageIndex+1)*paginator.pageSize-paginator.pageSize)+(i+1)}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="name">
					<mat-header-cell class="mat-column-extratext" *matHeaderCellDef mat-sort-header>Name
					</mat-header-cell>
					<mat-cell class="mat-column-extratext" *matCellDef="let enquiry">{{enquiry.name}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="contact">
					<mat-header-cell class="mat-column-extratext"  *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
					<mat-cell class="mat-column-extratext" *matCellDef="let enquiry">{{enquiry.contact}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="source" >
					<mat-header-cell class="mat-column-extratext" *matHeaderCellDef mat-sort-header>source</mat-header-cell>
					<mat-cell class="mat-column-extratext" *matCellDef="let enquiry">{{enquiry.source}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="date" >
					<mat-header-cell class="mat-column-date" *matHeaderCellDef mat-sort-header>Enquiry Date</mat-header-cell>
					<mat-cell class="mat-column-date" *matCellDef="let enquiry">{{enquiry.date| date:'dd-MM-yyyy'}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="followUpDate" >
					<mat-header-cell class="mat-column-date" *matHeaderCellDef mat-sort-header>Follow Up Date</mat-header-cell>
					<mat-cell class="mat-column-date" *matCellDef="let enquiry">{{enquiry.followUpDate| date:'dd-MM-yyyy'}}
					</mat-cell>
				</ng-container>


				<ng-container matColumnDef="status" >
					<mat-header-cell class="mat-column-id" *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
					<mat-cell class="mat-column-id"  *matCellDef="let enquiry">
						<span
							class="label label-lg label-light-{{ getItemCssClassByStatus(enquiry.status) }} label-inline">{{
							getItemStatusString(enquiry.status) }}</span>
							
					</mat-cell>
				</ng-container>


				<ng-container matColumnDef="actions">
					<mat-header-cell class="mat-column-actions" *matHeaderCellDef >
						Actions
					</mat-header-cell>
					<mat-cell  class="mat-column-actions" *matCellDef="let enquiry">
						<button type="button" (click)="followupAddmissionEnquiry(enquiry)" mat-icon-button color="primary"
						matTooltip="Followup Enquiry">
						<mat-icon>call</mat-icon>
					</button>&nbsp;
						<button type="button" (click)="editAddmissionEnquiry(enquiry)" mat-icon-button color="primary"
							matTooltip="Edit Enquiry">
							<mat-icon>create</mat-icon>
						</button>&nbsp;
						<button mat-icon-button color="warn" type="button" (click)="deleteEnquiry(enquiry)"
							matTooltip="Deactive Enquiry">
							<mat-icon>not_interested</mat-icon>
						</button>&nbsp;
						<!-- <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item type="button">
								<mat-icon>subject</mat-icon>
								<span>Remarks</span>
							</button>
							<button mat-menu-item type="button">
								<mat-icon>assignment</mat-icon>
								<span>Specifications</span>
							</button>
						</mat-menu> -->
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>