<kt-portlet>
	<kt-portlet-header [title]="'Admission Complain List'" [class]="'card-head-lg'"
		[viewLoading$]="dataSource.loading$">
		<ng-container ktPortletTools>
			<button (click)="addComplain()" mat-raised-button color="primary" matTooltip="Create new complain">New
				complain</button>
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header  [sticky]="true"-->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
			<div class="form-filtration">
				<!-- <div class="row align-items-center">
					<div class="col-md-2 kt-margin-bottom-10-mobile">
						<mat-form-field>
							<mat-select [(value)]="filterStatus" (selectionChange)="loadProductsList()"
								class="mat-form-field mat-form-field-fluid">
								<mat-option value="">All</mat-option>
								<mat-option value="0">Selling</mat-option>
								<mat-option value="1">Sold</mat-option>
							</mat-select>
							<mat-hint align="start">
								<strong>Filter by Status</strong>
							</mat-hint>
						</mat-form-field>
					</div>
					<div class="col-md-2 kt-margin-bottom-10-mobile">
						<mat-form-field>
							<mat-select [(value)]="filterCondition" (selectionChange)="loadProductsList()"
								class="mat-form-field mat-form-field-fluid">
								<mat-option value="">All</mat-option>
								<mat-option value="0">New</mat-option>
								<mat-option value="1">Used</mat-option>
							</mat-select>
							<mat-hint align="start">
								<strong>Filter by Contidion</strong>
							</mat-hint>
						</mat-form-field>
					</div> -->
				<div class="col-md-4 kt-margin-bottom-10-mobile">
					<mat-form-field>
						<input matInput placeholder="Search product" #searchInput placeholder="Search"
							class="mat-form-field mat-form-field-fluid">
						<mat-hint align="start">
							<strong>Search in all fields</strong>
						</mat-hint>
					</mat-form-field>
				</div>
			</div>
		</div>

		<!-- <div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}">
				<div class="col-xl-12">
					<div class="form-group form-group-inline">
						<div class="form-label form-label-no-wrap">
							<label class="font-bold font-danger-">Selected records count:
								{{ selection.selected.length }}</label>
						</div>
						<div>
							<button mat-raised-button color="warn" (click)="deleteProducts()"
								matTooltip="Delete selected products" class="mat-button-mt-4">
								<mat-icon>delete</mat-icon>
								Delete All
							</button>&nbsp;
						
							<button mat-raised-button (click)="fetchProducts()" matTooltip="Fetch selected products"
								class="mat-button-mt-4">
								<mat-icon>clear_all</mat-icon>
								Fetch Selected Records
							</button>&nbsp;
						
							<button mat-raised-button (click)="updateStatusForProducts()"
								matTooltip="Update status for selected products" class="mat-button-mt-4">
								<mat-icon>update</mat-icon>
								Update status
							</button>
						</div>
					</div>
				</div>
			</div> -->

		<!-- </div> -->
		<!-- end::FILTERS & GROUP ACTIONS -->

		<div class="mat-table-wrapper">
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="id" matSortDirection="desc" matSortDisableClear>
				<!-- Checkbox Column -->
				<!-- <ng-container matColumnDef="select">
					<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
						<mat-checkbox (change)="$event ? masterToggle() : null"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()" [color]="'primary'">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *matCellDef="let row" class="mat-column-checkbox">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
							[color]="'primary'">
						</mat-checkbox>
					</mat-cell>
				</ng-container> -->
				<ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef >Id</mat-header-cell>
					<mat-cell *matCellDef="let complain; let i=index">{{i+1}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="complaintType">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Complaint Type</mat-header-cell>
					<mat-cell *matCellDef="let complain">{{complain.complaintType}}</mat-cell>
				</ng-container>
				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
					<mat-cell *matCellDef="let complain">{{complain.name}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="contact">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
					<mat-cell *matCellDef="let complain">{{complain.contact}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="date">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
					<mat-cell *matCellDef="let complain">{{complain.date | date:'dd-MM-yyyy'}}</mat-cell>
				</ng-container>


				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>
						Actions
					</mat-header-cell>
					<mat-cell *matCellDef="let complain">

						<button type="button" (click)="viewComplain(complain)" mat-icon-button color="primary"
							matTooltip="View Details">
							<mat-icon>menu</mat-icon>
						</button>&nbsp;
						

						<button type="button" (click)="editComplain(complain)" mat-icon-button color="primary"
							matTooltip="Edit Complain">
							<mat-icon>create</mat-icon>
						</button>&nbsp;
						<ng-container *ngIf="complain.image!=''">
							<button mat-icon-button color="primary" matTooltip="Download"
								(click)="downloadFile(complain)">
								<mat-icon>get_app</mat-icon>
							</button>&nbsp;
						</ng-container>
						<!-- <button mat-icon-button color="warn" type="button" (click)="deleteComplain(complain)"
							matTooltip="Delete Complain">
							<mat-icon>delete</mat-icon>
						</button>&nbsp; -->
						<!-- <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item type="button">
								<mat-icon>subject</mat-icon>
								<span>Remarks</span>
							</button>
							<button mat-menu-item type="button">
								<mat-icon>assignment</mat-icon>
								<span>Specifications</span>
							</button>
						</mat-menu> -->
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>