<div class="card card-custom"
	[ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">Details</h3>
		</div>
	</div>
	
		<div class="card-body">

			<table class="table table-striped">    
				<tbody><tr>
					<th class="border0">Complaint Id</th>
					<td class="border0">{{complain.id}}</td>
					<th class="border0">Complaint Type</th>
					<td class="border0">{{complain.complaintType}}</td>
				</tr>
				<tr>
					<th>Source</th>
					<td>{{complain.source}}</td>
					<th>Name</th>
					<td>{{complain.name}}</td>
				</tr>
				<tr>
					<th>Phone</th>
					<td>{{complain.contact}}</td>
					<th>Date</th>
					<td>{{complain.date | date:'dd-MM-yyyy'}}</td>
				</tr>
				<tr>
					<th>Description</th>
					<td>{{complain.description}}</td>
					<th>Action Taken</th>
					<td>{{complain.actionTaken}}</td>
				</tr>
				<tr>
					<th>Assigned</th>
					<td>{{complain.assigned}}</td>
					<th>Note</th>
					<td>{{complain.note}}</td>
				</tr>
			</tbody></table>
			
		</div>
		<div class="card-footer">
				
		</div>
	
</div>
