// export const DATE_FORMATS = {
//     parse: {
//       dateInput: "LL"
//     },
//     display: {
//       dateInput: "DD-MM-YYYY",
//       monthYearLabel: "YYYY",
//       dateA11yLabel: "LL",
//       monthYearA11yLabel: "YYYY"
//     }



// export const DATE_FORMATS = {
//   parse: {
//       dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
//   },
//   display: {
//       // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
//       dateInput: 'input',
//       monthYearLabel: {year: 'numeric', month: 'short'},
//       dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
//       monthYearA11yLabel: {year: 'numeric', month: 'long'},
//   }
// };

export const DATE_FORMATS = {
  parse: {
      dateInput: 'DD-MM-YYYY'
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'DD-MM-YYYY',
      monthYearLabel:'MMMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  }
};

