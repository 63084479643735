<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{getTitle()}}</h3>
		</div>
	</div>
	<form class="form" [formGroup]="visitorsBookForm">
		<div class="card-body">

			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
				(close)="onAlertClose($event)">
				Oh snap! Fill the requird field and try submitting again.
			</kt-alert>

			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Select Purpose" formControlName="purpose" required>
							<mat-option *ngFor="let purpose of visitorPuposeList" [value]="purpose.visitorsPurpose">
								{{purpose.visitorsPurpose}}
							</mat-option>
						</mat-select>
						<mat-error> Purpose is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Name" formControlName="name" required />
						<mat-error> Name is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Phone" formControlName="contact" (keypress)=_keyPress($event)
							required />
						<mat-error>Phone is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>

			</div>

			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input type="email" matInput placeholder="Enter Email" formControlName="email" />
						<mat-error>Email is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Id Card" formControlName="idProof" />
						<mat-error> Id Card is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter No Of Pepple" formControlName="noOfPepple"
							(keypress)=_keyPress($event) />
						<mat-error> No Of Pepple is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>

			</div>


			<div class="form-group row">

				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input [min]="minDate" [max]="maxDate" matInput [matDatepicker]="picker" placeholder="Choose a Date " formControlName="date" />
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
						<mat-hint align="start">Please enter
							<strong>Date </strong> in 'dd/MM/yyyy' format
						</mat-hint>
					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<div class="ngx-timepicker-field-example">
						<!-- <input placeholder="Choose a Time "
                        formControlName="inTime" [ngxTimepicker]="picker" />
                        <ngx-material-timepicker #picker></ngx-material-timepicker> -->
						<label>In Time</label>
						<ngx-timepicker-field [controlOnly]="true" placeholder="Choose a Time "
							[defaultTime]="'12:11 AM'" formControlName="inTime"></ngx-timepicker-field>
					</div>



				</div>

				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<div class="ngx-timepicker-field-example">

						<label>Out Time</label>
						<ngx-timepicker-field [controlOnly]="true" placeholder="Choose a Time "
							[defaultTime]="'12:11 AM'" formControlName="outTime"></ngx-timepicker-field>
					</div>
					<!-- <ngx-mat-timepicker formControlName="outTime"></ngx-mat-timepicker> -->

				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput rows="2" placeholder="Enter Note" formControlName="note"></textarea>
					</mat-form-field>

				</div>


			</div>

			<div class="col-lg-12 kt-margin-bottom-20-mobile">
				<!-- <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label>Drop file here!</ngx-dropzone-label>
                    <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  
                </ngx-dropzone> -->
				<ngx-dropzone (change)="onSelect($event)">
					<ngx-dropzone-label>Drop file here!</ngx-dropzone-label>

					<ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
						[removable]="true" (removed)="onRemove(f)">
						<!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
					</ngx-dropzone-image-preview>
				</ngx-dropzone>
			</div>


		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
						matTooltip="Cancel changes">
						Cancel
					</button>&nbsp;
					<button type="button" mat-raised-button color="primary" (click)="onSubmit()"
						[disabled]="viewLoading" matTooltip="Save changes">
						Save
					</button>
				</div>
			</div>
		</div>
	</form>
</div>