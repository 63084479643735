import { PhoneCallLogService } from '../../../../../core/front-office/_services/phone-call-log.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralCallModel,  } from '../../../../../core/front-office';
// // Services and Models
// import { DeliveryPersonModel, DeliveryPersonUpdated, DeliveryPersonOnServerCreated, selectLastCreatedDeliveryPersonId, selectDeliveryPersonsActionLoading } from '../../../../../core/master-entry';
// import { EmployeeModel } from '../../../../../core/payroll/_models/employee.model';



@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-phone-call-log-view-dialog',
	templateUrl: './phone-call-log-view.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None
})
export class PhoneCallLogViewDialogComponent implements OnInit, OnDestroy {
	
	// Public properties
	phoneCall: GeneralCallModel;
	
	viewLoading = false;
	// Private properties
	private componentSubscriptions: Subscription;


	constructor(public dialogRef: MatDialogRef<PhoneCallLogViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService,
		private phoneCallLogService:PhoneCallLogService,
		private layoutUtilsService:LayoutUtilsService) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
		// this.store.pipe(select(selectPhoneCallLogsActionLoading)).subscribe(res => this.viewLoading = res);
		// loadding
		this.phoneCall = this.data.phoneCall;
	
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

}

