<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
    <div class="card-header">
        <div class="card-title">
            <h3 class="card-label">{{getTitle()}}</h3>
        </div>

        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <mat-icon class="close-icon" color="warn">close</mat-icon>
        </button>
    </div>
    <form class="form" [formGroup]="enquiryFollowupForm">
        <div class="card-body" [perfectScrollbar]="{ wheelPropagation: false }"
            [ngStyle]="{ height: '500px', position: 'relative' }">

            <!-- <div class="card-body-progress">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div> -->

            <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
                (close)="onAlertClose($event)">
                Oh snap! Ch ange a few things up and try submitting again. </kt-alert>

            <div class="form-group kt-form__group row">
                <div class="col-lg-8 kt-margin-bottom-20-mobile">


                    <div class="form-group row">
                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input  [min]="minDate" [max]="maxDate" matInput [matDatepicker]="picker" placeholder="Follow Up Date"
                                    formControlName="date" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint align="start">Please enter
                                    <strong>Date </strong> in 'dd/MM/yyyy' format
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <input  [min]="minDate" [max]="maxDate" matInput [matDatepicker]="picker1" placeholder="Next Follow Up Date"
                                    formControlName="nextDate" />
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-hint align="start">Please enter
                                    <strong>Follow Up Date</strong> in 'dd/MM/yyyy' format
                                </mat-hint>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="form-group row">
                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <textarea matInput rows="2" placeholder="Response"
                                    formControlName="response"></textarea>
                            </mat-form-field>
                        </div>

                        <div class="col-lg-6 kt-margin-bottom-20-mobile">
                            <mat-form-field class="mat-form-field-fluid">
                                <textarea matInput rows="2" placeholder="Note" formControlName="note"></textarea>
                            </mat-form-field>
                        </div>

                    </div>
                 
                    <div class="d-flex justify-content-between">
                        <div class="mr-2"></div>
                        <div>
                            <!-- <button type="button" mat-raised-button [mat-dialog-close]="data.animal"
                                        cdkFocusInitial matTooltip="Cancel changes">
                                        Cancel
                            </button>&nbsp; -->
                            <button type="button" mat-raised-button color="primary" (click)="onSubmit()"
                                [disabled]="viewLoading" matTooltip="Save changes">
                                Save
                            </button>
                        </div>
                    </div>
                    <hr>




                    <div class="form-group row">
                        <div class="col-lg-12 kt-margin-bottom-20-mobile">

                            <h5> Follow Up ({{enquiry.name}})</h5>
                        </div>
                    </div>
                    <!-- <hr> -->
                    <div class="form-group row">
                        <div class="col-lg-12 kt-margin-bottom-20-mobile">
                            <ng-container *ngFor=" let followup of followupList">

                            
                            <div class="timeline timeline-6 mt-3">
                                <div class="timeline-item align-items-start">
                                    <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">{{followup.date|date:'dd-MM-yyyy'}}
                                    </div>
                                    <div class="timeline-badge">
                                        <i class="fa fa-genderless text-warning icon-xl"></i>
                                    </div>
                                    <div class="font-weight-mormal font-size-lg timeline-content  pl-3">
                                        <div class="form-group row">
                                            <div class="col-lg-10 kt-margin-bottom-20-mobile">
                                                <span>{{followup.userFirstName}} {{followup.userLastName }}</span><br>
                                                <span>{{followup.response}}</span><br>
                                                <span>{{followup.note}}</span><br>
                                            </div>
                                            <div class="col-lg-2 kt-margin-bottom-20-mobile">
                                                <button mat-icon-button color="warn" type="button"
                                                    (click)="deleteEnquiryFollowup(followup)"
                                                    matTooltip="Delete Enquiry followup">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </ng-container> 
                            <!-- <div class="timeline timeline-6 mt-3">
                                <div class="timeline-item align-items-start">
                                    <div class="timeline-label font-weight-bolder text-dark-75 font-size-lg">21-03-2020
                                    </div>
                                    <div class="timeline-badge">
                                        <i class="fa fa-genderless text-warning icon-xl"></i>
                                    </div>
                                    <div class="font-weight-mormal font-size-lg timeline-content  pl-3">
                                        <div class="form-group row">
                                            <div class="col-lg-10 kt-margin-bottom-20-mobile">
                                                <span>login by</span><br>
                                                <span>description</span><br>
                                                <span>note</span><br>
                                            </div>
                                            <div class="col-lg-2 kt-margin-bottom-20-mobile">
                                                <button mat-icon-button color="warn" type="button"
                                                    (click)="deleteEnquiryFollowup()"
                                                    matTooltip="Delete Enquiry followup">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div> -->






                        </div>
                    </div>

                </div>


                <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <table style="width: 100%;border-bottom: 1px lightgray;">
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <label>Created By: Shivraj Bamne</label>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Status"
                                            (selectionChange)="onStatusSelectChange($event.value)">
                                            <mat-option value="active"> Active </mat-option>
                                            <mat-option value="passive">Passive </mat-option>
                                            <mat-option value="dead"> Dead </mat-option>
                                            <mat-option value="won"> Won </mat-option>
                                            <mat-option value="lost">Lost</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </td>
                        </tr>

                        <hr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Enquiry Date: {{enquiresDates?.enquirydate| date:'dd-MM-yyyy'}} </span>

                            </td>
                        </tr>
                        <tr>

                            <td style="text-align: left;" colspan="2">
                                <span> Last Follow Up Date: {{enquiresDates?.lastFollowUpdate| date:'dd-MM-yyyy'}}</span>

                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Next Follow Up Date: {{enquiresDates?.nextFollowUpdate| date:'dd-MM-yyyy'}}</span>

                            </td>

                        </tr>

                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Phone: {{enquiry.contact}}</span>

                            </td>

                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Address: {{enquiry.address}} </span>

                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Reference: {{enquiry.reference}} </span>

                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Description: {{enquiry.description}} </span>

                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Note: {{enquiry.note}} </span>

                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Source: {{enquiry.source}}</span>

                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Assigned: {{enquiry.assigned}} </span>

                            </td>
                        </tr>

                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Email: {{enquiry.email}}</span>

                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Class: {{enquiry.classes}}</span>

                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;" colspan="2">
                                <span> Number Of Child: {{enquiry.noOfChild}}</span>

                            </td>
                        </tr>


                    </table>
                </div>
            </div>
        </div>
    </form>
</div>