import { VisitorBookService } from '../../../../../core/front-office/_services/visitor-book.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VisitorBookModel, VisitorPurposeService, VisitorPurposeModel } from '../../../../../core/front-office';
import { UploadFileS3BucketService } from 'src/app/core/upload-file-s3-buket/uploadFileS3Bucket.service';
import { Constants } from 'src/app/core/api_url';
@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-visitor-book-view-dialog',
	templateUrl: './visitor-book-view.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None
})
export class VisitorBookViewDialogComponent implements OnInit, OnDestroy {
// Public properties
visitorsBook: VisitorBookModel;

viewLoading = false;
// Private properties
private componentSubscriptions: Subscription;

constructor(public dialogRef: MatDialogRef<VisitorBookViewDialogComponent>,
	@Inject(MAT_DIALOG_DATA) public data: any,
	private fb: FormBuilder,
	private store: Store<AppState>,
	private typesUtilsService: TypesUtilsService,
	private purposeService:VisitorPurposeService,
	private layoutUtilsService: LayoutUtilsService,
	private visitorBookService: VisitorBookService,
	private uploadFileS3BucketService:UploadFileS3BucketService) {
}

/** 
 * On init
 */
ngOnInit() {

	// this.store.pipe(select(selectVisitorBooksActionLoading)).subscribe(res => this.viewLoading = res);
	// loadding
	debugger
	this.visitorsBook = this.data.visitorsBook;

	
	
}


/**
 * On destroy
 */
ngOnDestroy() {
	if (this.componentSubscriptions) {
		this.componentSubscriptions.unsubscribe();
	}
}


}

