import { VisitorPurposeService } from './../_services/visitor-purpose.service';
// import { selectProductsInitWaitingMessage } from './../_selectors/product.selectors';
// RxJS
import { Observable, of } from "rxjs";
import 'rxjs/add/observable/of';
import { catchError, delay, distinctUntilChanged, finalize } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';


export class VisitorPurposesDataSource extends BaseDataSource {
  constructor(private purposeService:VisitorPurposeService) {
    super();
  //   this.loading$ = this.store.pipe(
  //     select(selectVisitorPurposesPageLoading)
  //   );

  //   this.isPreloadTextViewed$ = this.store.pipe(
  //     select(selectVisitorPurposesShowInitWaitingMessage)
  //   );

  //   this.store.pipe(
  //     select(selectVisitorPurposesInStore)
  //   ).subscribe((response: QueryResultsModel) => {
  //     this.paginatorTotalSubject.next(response.totalCount);
  //     this.entitySubject.next(response.items);
  //   });
  }

  loadPageRequesed(page) {
    this.loading$ = Observable.of(true);
    this.isPreloadTextViewed$ = Observable.of(true);
    this.purposeService.findVisitorPurposes(page).pipe(
      catchError(() => of([])),
      finalize(() => this.isPreloadTextViewed$ = this.loading$ = Observable.of(false),)
    ).subscribe(response => {
      this.loading$ = Observable.of(false);
      this.isPreloadTextViewed$ = Observable.of(false);
      const data = response['data'];
      this.paginatorTotalSubject.next(data.totalElements);
      this.entitySubject.next(data['content']);
    })
  }

}
