import { EnquiryFollowupModel } from './../../../../../core/front-office/_models/enquiry-followup.model';
import { StaffModel } from './../../../../../core/human-resource/_models/staff.model';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { AdmissionEnquiryService } from './../../../../../core/front-office/_services/admission-enquiry.service';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnquiryModel, ReferenceModel, SourceModel, SourceService, ReferenceService } from '../../../../../core/front-office';
import { StudentClassService } from '../../../../../core/academics';
import { StaffService } from 'src/app/core/human-resource';
import { LocalStorageVales } from 'src/app/core/constants/local-storage';

// // Services and Models
// import { DeliveryPersonModel, DeliveryPersonUpdated, DeliveryPersonOnServerCreated, selectLastCreatedDeliveryPersonId, selectDeliveryPersonsActionLoading } from '../../../../../core/master-entry';
// import { EmployeeModel } from '../../../../../core/payroll/_models/employee.model';



@Component({
    // tslint:disable-next-line:component-selector
    selector: 'kt-admission-enquiry-followup-dialog',
    templateUrl: './admission-enquiry-followup.dialog.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})
export class AdmissionEnquiryFollowupDialogComponent implements OnInit, OnDestroy {

    // Public properties
    enquiry:EnquiryModel
    enquiryFollowup: EnquiryFollowupModel;
    enquiryFollowupForm: FormGroup;
    hasFormErrors = false;
    viewLoading = false;
    // Private properties
    private componentSubscriptions: Subscription;

    referenceList: ReferenceModel[] = [];
    sourceList: SourceModel[] = [];
    classList = []
    //assigned list
    assignedList: StaffModel[] = [];

    followupList:EnquiryFollowupModel[]=[];
    minDate: Date;
    maxDate: Date;
    constructor(public dialogRef: MatDialogRef<AdmissionEnquiryFollowupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private typesUtilsService: TypesUtilsService,
        private referenceService: ReferenceService,
        private sourceService: SourceService,
        private studentClassService: StudentClassService,
        private staffService: StaffService,
        private enquiryService: AdmissionEnquiryService,
        private layoutUtilsService: LayoutUtilsService
    ) {
        let currentSession = LocalStorageVales.getSession();
		this.setMinMaxYear(currentSession.split('-')[0]);
    }
    setMinMaxYear(currentYear) {
		// Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
		const currentYear1 = new Date().getFullYear();
		let netyear = +currentYear + 1;
		this.minDate = new Date(currentYear, 0, 1);
		this.maxDate = new Date(netyear, 11, 31);
	}
    enquiresDates;
    /**
     * On init
     */
    ngOnInit() {

        // this.store.pipe(select(selectAdmissionEnquirysActionLoading)).subscribe(res => this.viewLoading = res);
        this.enquiryFollowup =new EnquiryFollowupModel();
        this.enquiryFollowup.clear();

        this.enquiry = this.data.enquiry;
        this.getFollowupById();
        this.createForm();
      

    }

getFollowupById(){
this.enquiryService.getAdmissionEnquirysFollowup(this.enquiry.id).subscribe(res=>{
console.log(res);
const data=res['data']

this.followupList=data['followUp'];
this.enquiresDates =data['summary']
},error=>{
    })
}



    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    createForm() {
        // date: string;
        // enquiryId: number;
        // id: number;
        // nextDate: string;
        // note: string;
        // response: string;
        // userFirstName: string;
        // userId: number;
        // userLastName: string;




        this.enquiryFollowupForm = this.fb.group({
            date: [this.typesUtilsService.getDateFromString(this.enquiryFollowup.date), Validators.compose([Validators.nullValidator])],
            enquiryId: [this.enquiry.id, ''],
            id: [this.enquiryFollowup.id,],
            nextDate: [this.enquiryFollowup.nextDate, Validators.required],
            note: [this.enquiryFollowup.note, ''],
            response: [this.enquiryFollowup.response, ''],
            userFirstName: [this.enquiryFollowup.userFirstName, ''],
            userId: [this.enquiryFollowup.userId, ''],
            userLastName: [this.enquiryFollowup.userLastName, ''],
            enquiryStatus: ['', ''],
        });
    }
    onStatusSelectChange(status){
        this.enquiryService.updateAdmissionEnquiryStatus(this.enquiry.id,status).subscribe(res=>{
            console.log(res);
            this.layoutUtilsService.showActionNotification("Enquiry followup status changed", MessageType.Success);
        },err=>{
            this.layoutUtilsService.showActionNotification("Error to change status enquiry followup  ", MessageType.Failed);
        });
    }
    deleteEnquiryFollowup(followup){
       
        const _title = 'Enquiry Followup Delete';
        const _description = 'Are you sure to permanently delete this enquiry followup?';
        const _waitDesciption = 'Enquiry Followup is deleting...';
        const _deleteMessage = `Enquiry Followup has been deleted`;
        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
              return;
            }
        this.enquiryService.deleteAdmissionEnquiryFollowup(followup.id).subscribe(res=>{
            console.log(res);
         
         
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
            this.getFollowupById();
        },err=>{
        
            this.layoutUtilsService.showActionNotification("Error to delete enquiry followup", MessageType.Delete);
        });
    })
    }
    /**
     * Returns page title
     */
    getTitle(): string {
        return 'Follow Up Admission Enquiry';
    }

    /**
     * Check control is invalid
     * @param controlName: string
     */
    isControlInvalid(controlName: string): boolean {
        const control = this.enquiryFollowupForm.controls[controlName];
        const result = control.invalid && control.touched;
        return result;
    }

    /** ACTIONS */

    /**
     * Returns prepared enquiry
     */
    prepareenquiry(): EnquiryFollowupModel {
        const controls = this.enquiryFollowupForm.controls;
        const _enquiryFollowup = new EnquiryFollowupModel();
        _enquiryFollowup.id = this.enquiryFollowup.id;

        const _date = controls.date.value;
        if (_date) {
            _enquiryFollowup.date = this.typesUtilsService.dateFormat(_date);
        } else {
            _enquiryFollowup.date = '';
        }
        const _nextDate = controls.nextDate.value;
        if (_nextDate) {
            _enquiryFollowup.nextDate = this.typesUtilsService.dateFormat(_nextDate);
        } else {
            _enquiryFollowup.nextDate = '';
        }
        _enquiryFollowup.isActive = 'yes';

        _enquiryFollowup.enquiryId = controls.enquiryId.value;
        _enquiryFollowup.note = controls.note.value;
        _enquiryFollowup.response = controls.response.value;
        _enquiryFollowup.note = controls.note.value;
        _enquiryFollowup.userFirstName = controls.userFirstName.value;
        // _enquiryFollowup.userId = controls.userId.value;
        _enquiryFollowup.userId = parseInt(localStorage.getItem('userId'));
        _enquiryFollowup.userLastName = controls.userLastName.value;



        return _enquiryFollowup;
    }

    /**
     * On Submit
     */
    onSubmit() {
        debugger
        this.hasFormErrors = false;
        const controls = this.enquiryFollowupForm.controls;
        /** check form */
        if (this.enquiryFollowupForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        const editedenquiry = this.prepareenquiry();
        // if (editedenquiry.id > 0) {
        //     this.updateEnquiry(editedenquiry);
        // } else {
        this.createEnquiry(editedenquiry);
        // }



    }

    /**
     * Update enquiry
     *
     * @param _enquiry: EnquiryModel
     */
    updateEnquiry(_enquiry: EnquiryModel) {
        // const updateEnquiry: Update<EnquiryModel> = {
        // 	id: _enquiry.id,
        // 	changes: _enquiry
        // };
        // this.store.dispatch(new AdmissionEnquiryUpdated({
        // 	partialAdmissionEnquiry: updateEnquiry,
        // 	enquiry: _enquiry
        // }));

        // Remove this line
        // of(undefined).pipe(delay(1000)).subscribe(() => this.dialogRef.close({ _enquiry, isEdit: true }));
        // Uncomment this line
        // this.dialogRef.close({ _enquiry, isEdit: true }

        this.enquiryService.updateAdmissionEnquiry(_enquiry).subscribe(res => {
            this.layoutUtilsService.showActionNotification('Admission enquiry has been updated', MessageType.Update);
            this.dialogRef.close({ _enquiry, isEdit: true });
        }, err => {
            this.layoutUtilsService.showActionNotification('Error to update admission enquiry', MessageType.Failed);
            this.dialogRef.close({ _enquiry, isEdit: true });
        })



    }

    /**
     * Create enquiry
     *
     * @param _enquiry: EnquiryModel
     */
    createEnquiry(_enquiry: EnquiryFollowupModel) {
        // this.store.dispatch(new AdmissionEnquiryOnServerCreated({ enquiry: _enquiry }));
        // this.componentSubscriptions = this.store.pipe(
        // 	select(selectLastCreatedAdmissionEnquiryId),
        // 	delay(1000), // Remove this line
        // ).subscribe(res => {
        // 	if (!res) {
        // 		return;
        // 	}

        // 	this.dialogRef.close({ _enquiry, isEdit: false });
        // });

        this.enquiryService.admissionEnquiryFollowUp(_enquiry).subscribe(res => {

            this.layoutUtilsService.showActionNotification('Admission followup has been recorded', MessageType.Create);
            // this.dialogRef.close({ _enquiry, isEdit: false });
           this.getFollowupById();
        }, err => {
            this.layoutUtilsService.showActionNotification('Error to recorded admission followup', MessageType.Failed);
            this.dialogRef.close({ _enquiry, isEdit: false });
        })


    }

    /** Alect Close event */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    _keyPress(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();

        }
    }
}

