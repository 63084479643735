import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-front-office',
  templateUrl: './front-office.component.html',
  styleUrls: ['./front-office.component.scss']
})
export class FrontOfficeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
