<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{getTitle()}}</h3>
		</div>

		<button mat-icon-button class="close-button" [mat-dialog-close]="true">
			<mat-icon class="close-icon" color="warn">close</mat-icon>
		</button>
	</div>
	<form class="form" [formGroup]="enquiryForm">
		<div class="card-body">

			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
				(close)="onAlertClose($event)">
				Oh snap! Fill the requird field and try submitting again.
			</kt-alert>

			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Name" formControlName="name" required />
						
						<!-- <mat-error *ngIf="isControlHasError('name', 'required');else elseData">
							<strong>Please, Enter only alphabeats.</strong>
						</mat-error>
						<ng-template #elseData> -->
							<mat-error> Name is
								<strong>required</strong>
							</mat-error>
						<!-- </ng-template> -->

					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Mobile No" formControlName="contact" required
							(keypress)=_keyPress($event) />

						<mat-error *ngIf="isControlHasError('contact', 'minlength');else elseData">
							<strong>Please, Enter 10 digit Mobile Number.</strong>
						</mat-error>
						<ng-template #elseData>
							<mat-error *ngIf="isControlHasError('contact', 'maxlength');else elseData1">
								<strong>Please, Enter 10 digit Mobile Number.</strong>
							</mat-error>
						</ng-template>
						<ng-template #elseData1>
							<mat-error>
								Contact is
								<strong>required</strong>
							</mat-error>
						</ng-template>

					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input type="email" matInput placeholder="Enter Email" formControlName="email" required />
						<mat-error>Email is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>
			</div>

			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput rows="2" placeholder="Enter Address" formControlName="address"></textarea>
					</mat-form-field>
				</div>



				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput rows="2" placeholder="Enter Description"
							formControlName="description"></textarea>
					</mat-form-field>
				</div>

				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput rows="2" placeholder="Enter Current School" formControlName="note"></textarea>
					</mat-form-field>

				</div>
			</div>


			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input [min]="minDate" [max]="maxDate" matInput  [matDatepicker]="picker" placeholder="Choose a Date " formControlName="date" />
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
						<mat-hint align="start">Please enter
							<strong>Date </strong> in 'dd/MM/yyyy' format
						</mat-hint>
					</mat-form-field>
				</div>



				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input [min]="minDate" [max]="maxDate"  matInput [matDatepicker]="picker1" placeholder="Choose a Follow Up Date"
							formControlName="followUpDate" />
						<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
						<mat-datepicker #picker1></mat-datepicker>
						<mat-hint align="start">Please enter
							<strong>Follow Up Date</strong> in 'dd/MM/yyyy' format
						</mat-hint>
					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<!-- <mat-form-field class="mat-form-field-fluid">
						<input  matInput placeholder="Enter Assigned" formControlName="assigned" />
						<mat-error>Assigned is
							<strong>required</strong>
						</mat-error>
						
					</mat-form-field>   (selectionChange)="onClassSelectChange($event.value)"-->

					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Enter Assigned" formControlName="assigned">
							<mat-option *ngFor="let data of assignedList" [value]="data.name+data.surname">
								{{data.name}} {{data.surname}}
							</mat-option>
						</mat-select>
					</mat-form-field>



				</div>
			</div>



			<div class="form-group row">
				<div class="col-lg-3 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Select Reference" formControlName="reference">
							<mat-option *ngFor="let reference of referenceList" [value]="reference.reference">
								{{reference.reference}}
							</mat-option>
						</mat-select>
						<mat-error> Reference is
							<strong>required</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="col-lg-3 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Select Source *" formControlName="sourceId"
							(selectionChange)="onSourceSelectChange($event.value)">
							<mat-option> --Select--</mat-option>
							<mat-option *ngFor="let source of sourceList" [value]="source.id">
								{{source.source}}
							</mat-option>
						</mat-select>
						<mat-error> Source is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>
				<div class="col-lg-3 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Select Class " formControlName="classesId" required
							(selectionChange)="onClassSelectChange($event.value)">
							<mat-option> --Select--</mat-option>
							<mat-option *ngFor="let class of classList" [value]="class.id">
								{{class.classses}}
							</mat-option>
						</mat-select>

						<mat-error> Class is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>

				<div class="col-lg-3 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter No Of Child" formControlName="noOfChild" required
							(keypress)=_keyPress($event) />
						<mat-error>No Of Child is
							<strong>required</strong>
						</mat-error>

					</mat-form-field>
				</div>


			</div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
						matTooltip="Cancel changes">
						Cancel
					</button>&nbsp;
					<button type="button" mat-raised-button color="primary" (click)="onSubmit()"
						[disabled]="viewLoading" matTooltip="Save changes">
						Save
					</button>
				</div>
			</div>
		</div>
	</form>
</div>