<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{getTitle()}}</h3>
		</div>

		<button mat-icon-button class="close-button" [mat-dialog-close]="true">
			<mat-icon class="close-icon" color="warn">close</mat-icon>
		</button>
	</div>
	<form class="form" [formGroup]="sessionForm">
		<div class="card-body">

			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
				(close)="onAlertClose($event)">
				Oh snap! Fill the requird field and try submitting again.
			</kt-alert>

			<div class="form-group row">
				<div class="col-lg-3 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Select session" formControlName="sessionId" 
							(selectionChange)="onSessionSelectChange($event.value)">
							<mat-option *ngFor="let session of sessionList" [value]="session.id">
								{{session.session}}
							</mat-option>
						</mat-select>
						<mat-error> Reference is
							<strong>required</strong>
						</mat-error>
					</mat-form-field>
				</div>
				
			</div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial
						matTooltip="Cancel changes">
						Cancel
					</button>&nbsp;
					<button type="button" mat-raised-button color="primary" (click)="onSubmit()"
						[disabled]="viewLoading" matTooltip="Save changes">
						Save
					</button>
				</div>
			</div>
		</div>
	</form>
</div>