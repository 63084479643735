import { PostalReceiveService } from '../../../../../core/front-office/_services/postal-receive.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DispatchReceiveModel } from '../../../../../core/front-office';
import { Constants } from 'src/app/core/api_url';
// // Services and Models
import { UploadFileS3BucketService } from 'src/app/core/upload-file-s3-buket/uploadFileS3Bucket.service';


@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-postal-receive-view-dialog',
	templateUrl: './postal-receive-view.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None
})
export class PostalReceiveViewDialogComponent implements OnInit, OnDestroy {


	// Public properties
	postalReceive: DispatchReceiveModel;
	
	viewLoading = false;
	// Private properties
	private componentSubscriptions: Subscription;


	constructor(public dialogRef: MatDialogRef<PostalReceiveViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService,
		private layoutUtilsService: LayoutUtilsService,
		private postalReceiveSevice: PostalReceiveService,
		private uploadFileS3BucketService:UploadFileS3BucketService) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
		// this.store.pipe(select(selectPostalReceivesActionLoading)).subscribe(res => this.viewLoading = res);
		// loadding
		this.postalReceive = this.data.postalReceive;
		
	}

	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

	
}

