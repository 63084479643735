import { MessageType, LayoutUtilsService } from 'src/app/core/_base/crud';
import { ComplaintService } from '../../../../../core/front-office/_services/complaint.service';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComplaintModel, ComplaintTypeModel, SourceModel, ComplaintTypeService, SourceService } from '../../../../../core/front-office';
import { TimestampModel } from '../../../../../core/front-office/_models/timestamp.model';
import { Constants } from 'src/app/core/api_url';
import { UploadFileS3BucketService } from 'src/app/core/upload-file-s3-buket/uploadFileS3Bucket.service';
// // Services and Models
// import { DeliveryPersonModel, DeliveryPersonUpdated, DeliveryPersonOnServerCreated, selectLastCreatedDeliveryPersonId, selectDeliveryPersonsActionLoading } from '../../../../../core/master-entry';
// import { EmployeeModel } from '../../../../../core/payroll/_models/employee.model';



@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-complain-view-dialog',
	templateUrl: './complain-view.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None
})
export class ComplainViewDialogComponent implements OnInit, OnDestroy {


	// Public properties
	complain: ComplaintModel;
	
	viewLoading = false;
	// Private properties
	private componentSubscriptions: Subscription;
	

	constructor(public dialogRef: MatDialogRef<ComplainViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private store: Store<AppState>,
		private typesUtilsService: TypesUtilsService,
		private complainTypeService: ComplaintTypeService,
		private sourceService: SourceService,
		private complaintService: ComplaintService,
		private layoutUtilsService: LayoutUtilsService,
		private uploadFileS3BucketService: UploadFileS3BucketService
	) {
	}

	/**
	 * On init
	 */
	ngOnInit() {
	
		this.complain = this.data.complain;


	}
	
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}

}

