import { AdmissionEnquiryFollowupDialogComponent } from './admission-enquiry/admission-enquiry-followup/admission-enquiry-followup.dialog.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import {MyDateAdapter } from './../../../core/constants/date-adapter';
import { DATE_FORMATS } from 'src/app/core/constants/date-formate';


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialsModule } from '../../partials/partials.module';
import { CoreModule } from '../../../core/core.module';
import { CodePreviewModule } from '../../partials/content/general/code-preview/code-preview.module';
import { Routes, RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatIconRegistry } from '@angular/material/icon';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';


//components
import { FrontOfficeComponent } from './front-office.component';
import { AdmissionEnquiryListComponent } from "./admission-enquiry/admission-enquiry-list/admission-enquiry-list.component";
import { VisitorBookListComponent } from './visitor-book/visitor-book-list/visitor-book-list.component';
import { PhoneCallLogListComponent } from './phone-call-log/phone-call-log-list/phone-call-log-list.component';
import { PostalDispatchListComponent } from './postal-dispatch/postal-dispatch-list/postal-dispatch-list.component';
import { PostalRecevieListComponent } from './postal-receive/postal-recevie-list/postal-recevie-list.component';
import { ComplainListComponent } from './complain/complain-list/complain-list.component';
import { PurposeComponent } from './setup-front-office/purpose/purpose.component';
import { ComplainTypeComponent } from './setup-front-office/complain-type/complain-type.component';
import { SourceComponent } from './setup-front-office/source/source.component';
import { ReferenceComponent } from './setup-front-office/reference/reference.component';
import { AdmissionEnquiryEditDialogComponent } from './admission-enquiry/admission-enquiry-edit/admission-enquiry-edit.dialog.component';
import { MaterialModule } from '../material/material.module';
import { PhoneCallLogEditDialogComponent } from './phone-call-log/phone-call-log-edit/phone-call-log-edit.dialog.component';
import { PostalDispatchEditDialogComponent } from './postal-dispatch/postal-dispatch-edit/postal-dispatch-edit.dialog.component';
import { PostalReceiveEditDialogComponent } from './postal-receive/postal-receive-edit/postal-receive-edit.dialog.component';
import { ComplainEditDialogComponent } from './complain/complain-edit/complain-edit.dialog.component';
import { VisitorBookEditDialogComponent } from './visitor-book/visitor-book-edit/visitor-book-edit.dialog.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { ModuleGuard } from '../../../core/auth';
import { InterceptService, TypesUtilsService, HttpUtilsService, LayoutUtilsService, DynamicSetActionsService } from '../../../core/_base/crud';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { ComplaintService, ComplaintTypeService, PhoneCallLogService, PostalDispatchService, PostalReceiveService, ReferenceService, SourceService, VisitorBookService, VisitorPurposeService , AdmissionEnquiryService} from 'src/app/core/front-office';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ComplainViewDialogComponent } from './complain/complain-view/complain-view.dialog.component';
import { PhoneCallLogViewDialogComponent } from './phone-call-log/phone-call-log-view/phone-call-log-view.dialog.component';
import { PostalReceiveViewDialogComponent } from './postal-receive/postal-receive-view/postal-receive-view.dialog.component';
import { PostalDispatchViewDialogComponent } from './postal-dispatch/postal-dispatch-view/postal-dispatch-view.dialog.component';
import { VisitorBookViewDialogComponent } from './visitor-book/visitor-book-view/visitor-book-view.dialog.component';


const routes: Routes = [
  {
    path: '',
    component: FrontOfficeComponent,
    children: [
      {
        path: '',
        redirectTo: 'roles',
        pathMatch: 'full'
      },
      {
        path: 'admissionenqury',
        component: AdmissionEnquiryListComponent
      },


      {
        path: 'visitorbook',
        component: VisitorBookListComponent
      },

      {
        path: 'phonecalllog',
        component: PhoneCallLogListComponent
      },
      {
        path: 'postaldispatch',
        component: PostalDispatchListComponent
      },
      {
        path: 'postalreceive',
        component: PostalRecevieListComponent
      },
      {
        path: 'complain',
        component: ComplainListComponent
      },
      {
        path: 'setup_front_office/purpose',
        component: PurposeComponent
      },
      {
        path: 'setup_front_office/complaintype',
        component: ComplainTypeComponent
      },
      {
        path: 'setup_front_office/source',
        component: SourceComponent
      },
      {
        path: 'setup_front_office/reference',
        component: ReferenceComponent
      },
    ]
  }
]

@NgModule({
  declarations: [
    FrontOfficeComponent,
    AdmissionEnquiryListComponent,
    AdmissionEnquiryEditDialogComponent,
    
    VisitorBookListComponent,
    VisitorBookEditDialogComponent,
    PhoneCallLogListComponent,
    PhoneCallLogEditDialogComponent,
    PhoneCallLogViewDialogComponent,
    VisitorBookViewDialogComponent,
    PostalDispatchListComponent,
    PostalDispatchEditDialogComponent,
    PostalDispatchViewDialogComponent,
    PostalRecevieListComponent,
    PostalReceiveEditDialogComponent,
    PostalReceiveViewDialogComponent,
    ComplainListComponent,
    ComplainEditDialogComponent,
    ComplainViewDialogComponent,
    PurposeComponent,
    ComplainTypeComponent,
    SourceComponent,
    ReferenceComponent,
    VisitorBookListComponent,
    AdmissionEnquiryFollowupDialogComponent,


  ],
  imports: [
    CommonModule,
    PartialsModule,
    NgbModule,
    CoreModule,
    CodePreviewModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    MaterialModule,
    TranslateModule.forChild(),
    NgxPermissionsModule.forChild(),


  ],
  providers: [
    NgbAlertConfig,
    MatIconRegistry,
    ModuleGuard,
    InterceptService,
    TypesUtilsService,
    HttpUtilsService,
    LayoutUtilsService,
    DynamicSetActionsService,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
 
    // {provide: DateAdapter, useClass: MyDateAdapter},
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},

    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },

    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px'
      }
    },



    //custom service
    AdmissionEnquiryService,
    ComplaintService,
    ComplaintTypeService,
    PhoneCallLogService,
    PostalDispatchService,
    PostalReceiveService,
    ReferenceService,
    SourceService,
    VisitorBookService,
    VisitorPurposeService,


  ],

  entryComponents: [
    // NgbdModalContentComponent
    AdmissionEnquiryEditDialogComponent,
    VisitorBookEditDialogComponent,
    VisitorBookViewDialogComponent,
    PhoneCallLogEditDialogComponent,
    PhoneCallLogViewDialogComponent,
    PostalDispatchEditDialogComponent,
    PostalDispatchViewDialogComponent,
    PostalReceiveEditDialogComponent,
    PostalReceiveViewDialogComponent,
    ComplainEditDialogComponent,
    ComplainViewDialogComponent, 
    AdmissionEnquiryFollowupDialogComponent
  ],
  exports: [RouterModule],
})
export class FrontOfficeModule { }
