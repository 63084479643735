
//models
export {EnquiryModel} from './_models/enquiry.model';
export {ComplaintTypeModel} from './_models/complaint-type.model';
export {ComplaintModel} from './_models/complaint.model';
export {DispatchReceiveModel} from './_models/dispose-dispatch-receive.model';
export {GeneralCallModel} from './_models/general-call.model';
export {ReferenceModel} from './_models/reference.model';
export {SourceModel} from './_models/source.model';
export {VisitorPurposeModel} from './_models/visitor-purpose.model';
export {VisitorBookModel} from './_models/visitor-book.model';

//datasource
export {EnquirysDataSource} from './_data-sources/enquiry.datasource';
export {PhoneCallLogsDataSource} from './_data-sources/phone-call-log.datasource';
export {ComplainTypesDataSource} from './_data-sources/complaint-type.datasource';
export {ComplainsDataSource} from './_data-sources/complaint.datasource';
export {PostalDispatchsDataSource} from './_data-sources/postal-dispatch.datasource';
export {PostalReceivesDataSource} from './_data-sources/postal-receive.datasource';
export {ReferencesDataSource} from './_data-sources/reference.datasource';
export {SourcesDataSource} from './_data-sources/source.datasource';
export {VisitorBooksDataSource} from './_data-sources/visitor-book.datasource';
export {VisitorPurposesDataSource} from './_data-sources/visitor-purpose.datasource';

// Services
export { AdmissionEnquiryService } from './_services/admission-enquiry.service';
export { ComplaintTypeService } from './_services/complaint-type.service'
export { ComplaintService} from './_services/complaint.service';
export { PhoneCallLogService} from './_services/phone-call-log.service';
export { PostalDispatchService} from './_services/postal-dispatch.service';
export { PostalReceiveService} from './_services/postal-receive.service';
export { ReferenceService} from './_services/reference.service';
export { SourceService} from './_services/source.service';
export { VisitorBookService} from './_services/visitor-book.service';
export { VisitorPurposeService } from './_services/visitor-purpose.service';


