<div class="card card-custom"
	[ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">{{getTitle()}}</h3>
		</div>
	</div>
	<form class="form" [formGroup]="phoneCallForm">
		<div class="card-body">

			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
				Oh snap! Fill the requird field and try submitting again.
			</kt-alert>

			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Name" formControlName="name" />
						<mat-error> Name is
							<strong>required</strong>
						</mat-error>
						
					</mat-form-field>
				</div>
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Phone" formControlName="contact" (keypress)=_keyPress($event)/>
						<mat-error>Phone is
							<strong>required</strong>
						</mat-error>
						
					</mat-form-field>
				</div>
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <mat-form-field class="mat-form-field-fluid">
                        <input [min]="minDate" [max]="maxDate" matInput [matDatepicker]="picker"
                            placeholder="Choose a Date "
                            formControlName="date" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-hint align="start">Please enter
                            <strong>Date </strong> in 'dd/MM/yyyy' format</mat-hint>
                    </mat-form-field>
                </div>

    
			</div>
			
			<div class="form-group row">
				<div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput rows="2" placeholder="Enter Description" formControlName="description"></textarea>
					</mat-form-field>
                </div>
                
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input [min]="minDate" [max]="maxDate" matInput  [matDatepicker]="picker1"
							placeholder="Choose a Follow Up Date"
							formControlName="followUpDate" />
						<mat-datepicker-toggle   matSuffix [for]="picker1"></mat-datepicker-toggle>
						<mat-datepicker #picker1></mat-datepicker>
						<mat-hint align="start">Please enter
							<strong>Next Follow Up Date</strong> in 'dd/MM/yyyy' format</mat-hint>
					</mat-form-field>
				</div>

                <div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input  matInput placeholder="Enter Call Dureation" formControlName="callDureation" />
						<mat-error>Call Dureation is
							<strong>required</strong>
						</mat-error>
						
					</mat-form-field>
                </div>
                
				
			</div>

		
			<div class="form-group row">
			
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<textarea matInput rows="2" placeholder="Enter Note" formControlName="note"></textarea>
					</mat-form-field>
				
				</div>
                <div class="col-lg-4 kt-margin-bottom-20-mobile">
                    <label >Call Type</label><br>
                    <mat-radio-group aria-label="Select an option" formControlName="callType" >
                        <mat-radio-button value="Incoming">Incoming</mat-radio-button>
                        <mat-radio-button value="Outgoing">Outgoing</mat-radio-button>
                      </mat-radio-group>
                </div>
			
			</div>

		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button type="button" mat-raised-button [mat-dialog-close]="data.animal" cdkFocusInitial matTooltip="Cancel changes">
						Cancel
					</button>&nbsp;
					<button type="button" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="viewLoading" matTooltip="Save changes">
						Save
					</button>
				</div>
			</div>
		</div>
	</form>
</div>
