
<!-- <ng-container *ngIf="session$| async as _session"> -->

<!-- <div class="brand"  (click)="editSession()"> -->

 <a href="javascript:;" class=" brandSession"   > <span (click)="editSession()">Current Session: {{session?.session}}
   </span></a>

  <ng-container *ngIf="asideSelfMinimizeToggle">
    <button class="brand-toggle btn btn-sm px-0" >
      <span class="svg-icon svg-icon-x" [inlineSVG]="'./assets/media/svg/icons/General/Edit.svg'"></span>
    </button>
  </ng-container>
<!-- </div> -->

<!-- class="brand-logo" -->
<!-- </ng-container> -->
<!-- end:: Brand -->

<!-- <style>
.brandSession {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
  height: 30px;
  /* // Minimized Aside Mode */
		/* .aside-minimize:not(.aside-minimize-hover) & {
			img {
				display: none;
			}
		} */
}
</style> -->