

export class EnquiryFollowupModel {

    date: string;
    enquiryId: number;
    id: number;
    nextDate: string;
    note: string;
    response: string;
    userFirstName: string;
    userId: number;
    userLastName: string;
    isActive: string;

    clear() {
        this.date = '';
        this.enquiryId = 0;
        this.id = 0;
        this.nextDate = '';
        this.note = '';
        this.response = '';
        this.userFirstName = '';
        this.userId = 0;
        this.userLastName = '';
        this.isActive = '';
    }


}

