<kt-portlet>
	<kt-portlet-header [sticky]="true" [title]="'Postal Dispatch List'" [class]="'card-head-lg'"
		[viewLoading$]="dataSource.loading$">
		<ng-container ktPortletTools>
			<button (click)="addPostalDispatch()" mat-raised-button color="primary" matTooltip="Create new postalDispatch">New
				Postal Dispatch</button>
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
			<div class="form-filtration">
				
					<div class="col-md-4 kt-margin-bottom-10-mobile">
						<mat-form-field>
							<input matInput placeholder="Search product" #searchInput placeholder="Search"
								class="mat-form-field mat-form-field-fluid">
							<mat-hint align="start">
								<strong>Search in all fields</strong>
							</mat-hint>
						</mat-form-field>
					</div>
				</div>
			</div>
        
            

	<div class="mat-table-wrapper">
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="id" matSortDirection="desc" matSortDisableClear>
				<ng-container matColumnDef="id">
					<mat-header-cell class="w-25" *matHeaderCellDef  >Id
					</mat-header-cell>
					<mat-cell *matCellDef="let enquiry;let i=index">{{i+1}}</mat-cell>
				</ng-container>
              
				<ng-container matColumnDef="toTitle">
					<mat-header-cell *matHeaderCellDef mat-sort-header >To Title
					</mat-header-cell>
					<mat-cell *matCellDef="let postalDispatch" >{{postalDispatch.toTitle}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="referenceNo">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Reference No</mat-header-cell>
					<mat-cell *matCellDef="let postalDispatch">{{postalDispatch.referenceNo}}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="fromTitle">
					<mat-header-cell *matHeaderCellDef mat-sort-header>From Title</mat-header-cell>
					<mat-cell *matCellDef="let postalDispatch">{{postalDispatch.fromTitle}}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="date">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
					<mat-cell *matCellDef="let postalDispatch">{{postalDispatch.date | date:'dd-MM-yyyy'}}</mat-cell>
                </ng-container>


				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>
						Actions
					</mat-header-cell>
					<mat-cell *matCellDef="let postalDispatch">

						<button type="button"(click)="viewPostalDispatch(postalDispatch)" mat-icon-button color="primary"
						matTooltip="View Details">
						<mat-icon>menu</mat-icon>
					</button>

				

						<button type="button" (click)="editPostalDispatch(postalDispatch)" mat-icon-button color="primary"
							matTooltip="Edit Postal Dispatch">
							<mat-icon>create</mat-icon>
						</button>
						<button mat-icon-button color="warn" type="button" (click)="deletePostalDispatch(postalDispatch)"
							matTooltip="Delete Postal Dispatch">
							<mat-icon>delete</mat-icon>
						</button>
						<ng-container *ngIf="postalDispatch.image!=''">
							<button mat-icon-button color="primary" matTooltip="Download"
							(click)="downloadFile(postalDispatch)">
							<mat-icon>get_app</mat-icon>
						  </button>
						</ng-container>
						<!-- <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="More actions">
							<mat-icon>more_vert</mat-icon>
						</button>
						<mat-menu #menu="matMenu">
							<button mat-menu-item type="button">
								<mat-icon>subject</mat-icon>
								<span>Remarks</span>
							</button>
							<button mat-menu-item type="button">
								<mat-icon>assignment</mat-icon>
								<span>Specifications</span>
							</button>
						</mat-menu> -->
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>