import { SourceService } from './../_services/source.service';
// import { selectProductsInitWaitingMessage } from './../_selectors/product.selectors';
// RxJS
import { Observable, of } from "rxjs";
import 'rxjs/add/observable/of';
import { catchError, delay, distinctUntilChanged, finalize } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';

export class SourcesDataSource extends BaseDataSource {
  constructor(private sourceService:SourceService) {
    super();
    // this.loading$ = this.store.pipe(
    //   select(selectSourcesPageLoading)
    // );

    // this.isPreloadTextViewed$ = this.store.pipe(
    //   select(selectSourcesShowInitWaitingMessage)
    // );

    // this.store.pipe(
    //   select(selectSourcesInStore)
    // ).subscribe((response: QueryResultsModel) => {
    //   this.paginatorTotalSubject.next(response.totalCount);
    //   this.entitySubject.next(response.items);
    // });
  }

  loadPageRequesed(page) {
    this.loading$ = Observable.of(true);
    this.isPreloadTextViewed$ = Observable.of(true);
    this.sourceService.findSources(page).pipe(
      catchError(() => of([])),
      finalize(() => this.isPreloadTextViewed$ = this.loading$ = Observable.of(false),)
    ).subscribe(response => {
      this.loading$ = Observable.of(false);
      this.isPreloadTextViewed$ = Observable.of(false);
      const data = response['data'];
      this.paginatorTotalSubject.next(data.totalElements);
      this.entitySubject.next(data['content']);
      // const result: QueryResultsModel = response[0];
      // const lastQuery: QueryParamsModel = response[1];
      // this.paginatorTotalSubject.next(result.totalCount);
      // this.entitySubject.next(result.items);
    })
  }



}
