import { PostalDispatchService } from './../../../../../core/front-office/_services/postal-dispatch.service';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
// NGRX
import { Update } from '@ngrx/entity';
import { Store, select } from '@ngrx/store';
// State
import { AppState } from '../../../../../core/reducers';
// CRUD
import { TypesUtilsService } from '../../../../../core/_base/crud';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DispatchReceiveModel } from '../../../../../core/front-office';
import { Constants } from 'src/app/core/api_url';

import { UploadFileS3BucketService } from 'src/app/core/upload-file-s3-buket/uploadFileS3Bucket.service';
import { LocalStorageVales } from 'src/app/core/constants/local-storage';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-postal-dispatch-edit-dialog',
	templateUrl: './postal-dispatch-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None
})
export class PostalDispatchEditDialogComponent implements OnInit, OnDestroy {
	
	
// Public properties
postalDispatch: DispatchReceiveModel;
postalDispatchForm: FormGroup;
hasFormErrors = false;
viewLoading = false;
// Private properties
private componentSubscriptions: Subscription;
files: File[] = [];

minDate: Date;
maxDate: Date;
constructor(public dialogRef: MatDialogRef<PostalDispatchEditDialogComponent>,
	@Inject(MAT_DIALOG_DATA) public data: any,
	private fb: FormBuilder,
	private store: Store<AppState>,
	private typesUtilsService: TypesUtilsService,
	private layoutUtilsService: LayoutUtilsService,
	private postalDispatchService:PostalDispatchService,
	private uploadFileS3BucketService:UploadFileS3BucketService) {
		let currentSession = LocalStorageVales.getSession();
		this.setMinMaxYear(currentSession.split('-')[0]);
}
setMinMaxYear(currentYear) {
	// Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
	const currentYear1 = new Date().getFullYear();
	let netyear = +currentYear + 1;
	this.minDate = new Date(currentYear, 0, 1);
	this.maxDate = new Date(netyear, 11, 31);
}
/**
 * On init
 */
ngOnInit() {
	// this.store.pipe(select(selectPostalDispatchsActionLoading)).subscribe(res => this.viewLoading = res);
	// loadding
	this.postalDispatch = this.data.postalDispatch;
	this.createForm();
}

/**
 * On destroy
 */
ngOnDestroy() {
	if (this.componentSubscriptions) {
		this.componentSubscriptions.unsubscribe();
	}
}

createForm() {
	this.postalDispatchForm = this.fb.group({

		address: [this.postalDispatch.address, ''],
		date: [this.typesUtilsService.getDateFromString(this.postalDispatch.date), Validators.compose([Validators.nullValidator])],
		fromTitle: [this.postalDispatch.fromTitle, Validators.required],
		image: [this.postalDispatch.image, ''],
		isActive: [this.postalDispatch.isActive, ''],
		note: [this.postalDispatch.note, ''],
		referenceNo: [this.postalDispatch.referenceNo,''],
		toTitle: [this.postalDispatch.toTitle, Validators.required],
		type: [this.postalDispatch.type, ''],
		// isActive: string;


	});
}

/**
 * Returns page title
 */
getTitle(): string {
	if (this.postalDispatch.id > 0) {
		return `Edit Postal Dispatch '${this.postalDispatch.toTitle}'`;
	}

	return 'New Postal Dispatch';
}

/**
 * Check control is invalid
 * @param controlName: string
 */
isControlInvalid(controlName: string): boolean {
	const control = this.postalDispatchForm.controls[controlName];
	const result = control.invalid && control.touched;
	return result;
}

/** ACTIONS */

/**
 * Returns prepared postalDispatch
 */
preparepostalDispatch(): DispatchReceiveModel {
	const controls = this.postalDispatchForm.controls;
	const _postalDispatch = new DispatchReceiveModel();
	_postalDispatch.id = this.postalDispatch.id;

	if(_postalDispatch.id>0){
		_postalDispatch.isActive = controls.isActive.value;
	}else{
		_postalDispatch.isActive = 'yes';
	}
		_postalDispatch.address = controls.address.value;
		const _date = controls.date.value;
		if (_date) {
			_postalDispatch.date = this.typesUtilsService.dateFormat(_date);
		} else {
			_postalDispatch.date = '';
		}
	_postalDispatch.fromTitle = controls.fromTitle.value;
	_postalDispatch.image = controls.image.value;
	
	_postalDispatch.note = controls.note.value;
	_postalDispatch.referenceNo = controls.referenceNo.value;
	_postalDispatch.toTitle = controls.toTitle.value;
	_postalDispatch.type = Constants.DISPATCH;
	// _postalDispatch.isActive='yes'
	return _postalDispatch;
}

/**
 * On Submit
 */
onSubmit() {
	this.hasFormErrors = false;
	const controls = this.postalDispatchForm.controls;
	if (Constants.fileUrl != '') {
		this.postalDispatchForm.controls.image.setValue(Constants.fileUrl.Location)
	  }
	/** check form */
	if (this.postalDispatchForm.invalid) {
		Object.keys(controls).forEach(controlName =>
			controls[controlName].markAsTouched()
		);

		this.hasFormErrors = true;
		return;
	}

	const editedpostalDispatch = this.preparepostalDispatch();
	if (editedpostalDispatch.id > 0) {
		this.updatePostalDispatch(editedpostalDispatch);
	} else {
		this.createPostalDispatch(editedpostalDispatch);
	}
}

/**
 * Update postalDispatch
 *
 * @param _postalDispatch: DispatchReceiveModel
 */
updatePostalDispatch(_postalDispatch: DispatchReceiveModel) {
	// const updatePostalDispatch: Update<DispatchReceiveModel> = {
	// 	id: _postalDispatch.id,
	// 	changes: _postalDispatch
	// };
	// this.store.dispatch(new PostalDispatchUpdated({
	// 	partialPostalDispatch: updatePostalDispatch,
	// 	postalDispatch: _postalDispatch
	// }));

	// integrate postalDispatch  update api

	// Remove this line
	// of(undefined).pipe(delay(1000)).subscribe(() => this.dialogRef.close({ _postalDispatch, isEdit: true }));
	// Uncomment this line
	// this.dialogRef.close({ _postalDispatch, isEdit: true })

	
	this.postalDispatchService.updatePostalDispatch(_postalDispatch).subscribe(res => {

		this.layoutUtilsService.showActionNotification('Postal dispatch  has been updated', MessageType.Update);
		this.dialogRef.close({ _postalDispatch, isEdit: true })

	}, err => {
		this.layoutUtilsService.showActionNotification('Error to update postal dispatch', MessageType.Failed);
		this.dialogRef.close({ _postalDispatch, isEdit: true })
	})


}

/**
 * Create postalDispatch
 *
 * @param _postalDispatch: DispatchReceiveModel
 */
createPostalDispatch(_postalDispatch: DispatchReceiveModel) {
	// this.store.dispatch(new PostalDispatchOnServerCreated({ postalDispatch: _postalDispatch }));
	// this.componentSubscriptions = this.store.pipe(
	// 	select(selectLastCreatedPostalDispatchId),
	// 	delay(1000), // Remove this line
	// ).subscribe(res => {
	// 	if (!res) {
	// 		return;
	// 	}

	// 	this.dialogRef.close({ _postalDispatch, isEdit: false });
	// });

	// integrate postalDispatch  create api

	this.postalDispatchService.createPostalDispatch(_postalDispatch).subscribe(res => {

		this.layoutUtilsService.showActionNotification('Postal dispatch has been created', MessageType.Create);
		this.dialogRef.close({ _postalDispatch, isEdit: false });

	}, err => {
		this.layoutUtilsService.showActionNotification('Error to created postal dispatch', MessageType.Failed);
		this.dialogRef.close({ _postalDispatch, isEdit: false });
	})
		

}

/** Alect Close event */
onAlertClose($event) {
	this.hasFormErrors = false;
}

onSelect(event) {
	console.log(event);
	this.files.push(...event.addedFiles);
	if (this.files.length > 0) {
		this.uploadFileS3BucketService.uploadfile(this.files, 'postal-dispatch')
		.subscribe(res => {
			console.log(res);
		
			if (res != '') {
			  this.postalDispatchForm.controls.image.setValue(res.Location)
			}
		
		
			}, err => {
		
			});
	  }
  }
   
  onRemove(event) {
	console.log(event);
	this.files.splice(this.files.indexOf(event), 1);
  }

  _keyPress(event: any) {
	const pattern = /[0-9]/;
	let inputChar = String.fromCharCode(event.charCode);
	if (!pattern.test(inputChar)) {
		event.preventDefault();

	}
}
}

