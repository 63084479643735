export class Constants {
   public static URL: any = {
      HOST_URL: 'https://app.rkate.com:8080/',
      // HOST_URL: 'https://192.168.0.29:8080/',
      
      // HOST_URL: 'https://192.168.0.4:8080/',
      accessToken: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXBlcmFkbWluQGdtYWlsLmNvbSIsIlNlc3Npb25JRCI6MSwiaWF0IjoxNjA1NjgwNTE4LCJleHAiOjE2MDU3NjY5MTh9.ylU_vhIgkXCnK_JPjpzUB2o-if_vtSf0eSTu4t-XQnk-MuolPdbe1W7rQvYagANBzlU3efKk8U57FqfMqiIFOg',
      sessionId: '1',
      // http://3.140.52.88:8080/swagger-ui.html#
   }
   public static fileUrl: any = '';
   public static libraryMember: any = '';

   public static DISPATCH: string = 'dispatch';
   public static RECEIVE: string = 'receive';
   public static HOLIDAY: string = 'holiday';
   public static PRESENT: string = 'present';

   public static StudentLeaveStatus: any = {
      All: 'all',
      Pending: '0',
      Approve: '1',
      Reject: '2'
   }
   // Note: Defined values for leave status 0 - Pending, 1 - Approve, 2 - Reject

   public static Pagination: any = {
      withPagination: '1',
      withoutPagination: '0'
   }
   public static SortOrder: any = {
      ASC: 'ASC',
      DESC: 'DESC'
   }

   public static DiscountStatus: any = {
      Applied: 'applied',
      Assigned: 'assigned'
   }

   public static Authentication: any = {
      Login: 'auth/login',
   }
   public static Role_permission: any = {
      // by rolename
      // Permission:'api/permission', 
      Role: 'api/role',
      Permission: 'api/permission/groups-category',
      RoleWisePermission: 'api/permission/role-wise-permission'
   
   }

   public static Front_Office: any = {
      Admission_Enquiry: 'api/enquiry',
      Complain: 'api/complaint',
      ComplainType: 'api/complaint-type',
      Phone_Call_Log: 'api/general-call',
      Postal_Dispatch: 'api/dispatch-receive',
      Postal_Receive: 'api/dispatch-receive',
      Visitor_Book: 'api/visitors-book',
      Visitor_Purpose: 'api/visitor-purpose',
      Reference: 'api/reference',
      Source: 'api/source',
   }

   public static Student_Information: any = {
      Student: 'api/student',
      Student_Category: 'api/category',
      Disable_Reason: 'api/disable-reason',
      Student_House: 'api/school-house',
      Disable_Student: 'api/student/api/disable-student',
      Online_Addmission: 'api/',
      User: 'api/user',
      Disable_Student_Disable: 'api/student/api/disable-student',
      Disable_Student_Enable: 'api/student/api/enable-student',
      StudentSearchAnyField: 'api/student/get-student-by-any-field'
   }

   public static Academics: any = {
      Class_TimeTable: 'api/subject-class-timetable',
      Teacher_TimeTable: 'api/staff-timetable',
      Assign_Class_Teacher: 'api/assign-class-teacher',
      Class: 'api/class',
      Section: 'api/section',
      Subject: 'api/subject',
      Subject_Group: 'api/subject-group',
     Promote_Student:'api/promote_student',
   }

   public static Attendance: any = {

      Approve_Leave: 'api/student-apply-leave',
      //   Attendance_By_Date:'',
      Student_Attendance: 'api/student-attendance',
      Attendance_Type: 'api/attendance-type',
      Approve_Disapprove_Leave: 'api/student-approve-leave'
   }


   public static Homework: any = {

      Homework_Add: 'api/homework',
      Homework_Evaluation: 'api/homework-evaluation'

   }
   public static Library: any = {

      Book: 'api/book',
      Library_Member: 'api/library-member',
      Library_student_Member: 'api/library-member/student',
      Library_Staff_Member: 'api/library-member/staff',
      Book_Issue_Return: 'api/library-member/issue'
   }


   public static Human_Resource: any = {
      Staff: 'api/staff',
      Staff_Designation: 'api/staffdesignation',
      Department: 'api/department',
      Leave_Type: 'api/leave-type',
      // Staff_Leave:'api/staff-leave',
      Staff_Leave_Request: 'api/staff-leave-request',
      Staff_Leave_Details: 'api/staff-leave-details',
      Staff_Payroll: 'api/staff-payroll',
      Staff_Rating: 'api/staff-rating',
      Staff_Attendance: 'api/staff-attendance',
      Roles: 'api/role',
      User: 'api/user'
   }
   public static User: any = {
      User: 'api/user'

   }

   public static Fees_Collection: any = {
      Fees_Group: 'api/fee-group',
      Fees_Type: 'api/fee-type',
      Fees_Discount: 'api/fee-discount',
      Fees_Master: 'api/feemaster',
      AssignStudentFeemaster: 'api/feemaster/assign/student',
      Assign_Student_Fees_discount: 'api/fee-discount/assign/student',
      Student_Fee_Deposite: 'api/student-fee-deposite',
      Student_Discount: 'api/student-discount',
     
      Student_Due_Fees: 'api/studentfee/pending',  // 
      FeesReminder:'api/fee-reminder',
      // /api/fee-discount/assign/student
      // /api/fee-discount/assign/student/{fee_discount_id}


   }


   public static Incomes: any = {
      Income: 'api/income',
      IncomeHead: 'api/income-head',
   }

   public static Expenses: any = {
      Expense: 'api/expense',
      ExpenseHead: 'api/expense-head',
   }

   public static Transports: any = {
      Route: 'api/transport-route',
      Vehicle: 'api/vehicle',
      AssignVehicle: 'api/vehicle-route',
   }


   public static Hostels: any = {
      Hostel: 'api/hostel',
      AvaliableHostel: 'api/hostel/get-available-hostel',
   
      RoomType: 'api/room-type',
      HostelRoom: 'api/hostel-room',
   }
   public static Inventory: any = {
      ItemCategory: 'api/item-category',
      AddItem: 'api/item',
      HostelRoom: 'api/hostel-room',
      ItemStore: 'api/item-Store',
      ItemSupplier: 'api/item-supplier',
      ItemStock: 'api/item-stock',
      ItemIssue: 'api/item-issue'
   }


   public static Exams: any = {
      ExamGroup: 'api/exam-group',
      Exam: 'api/exam',
      HostelRoom: 'api/hostel-room',
      AssignStudentExam: 'api/exam/students',
      ExamSubject: 'api/exam/subjects',
      ExamSubjectMarks: 'api/exam/subjects/marks',
      ExamResult: 'api/exam/exam-result'

   }

   public static System_Settings: any = {
      Session: 'api/session',
      Email: 'api/email',
      Sms: 'api/sms',
      Change_Session: 'api/session/changesession',
      Role: 'api/role'
   }



   public static Student_Report: any = {
      Student_Report: 'api/student-report/studentreport',
      Student_Login_Credential_Report: 'api/student-report/logindetailreport',
      Sibling_Report: 'api/student-report/sibling_report',
      Student_Profile_Report: 'api/student-report/student_profile_report',
      Student_History_Report: 'api/student-report/student_history_report',
      Class_Subject_Report: 'api/student-report/class_subject',
      Guardian_report: 'api/student-report/guardianreport',
      Admission_report: 'api/',
      Homework_Evaluation_report: 'api/student-report/homework_evaluation_report'

   }

   public static Transport_Report: any = {
      Transport_Report: 'api/inventory-report/studenttransportdetails'
   }

   public static Inventory_Report: any = {
      Add_Item_Report: 'api/inventory-report/additem',
      Inventory_Stock_Report: 'api/inventory-report/inventorystock',
      Issue_Item_Report: 'api/inventory-report/issueinventory',
   }

   public static Library_Report: any = {
      Book_Issue_Report: 'api/library-report/bookissuereport',
      Book_Due_Report: 'api/library-report/bookduereport',
      Book_Inventory_Report: 'api/library-report/bookinventory',
      Book_Issue_Return_Report: 'api/library-report/bookreturnreport'

   }
   public static Human_Resource_Report: any = {
      Payroll_Report: 'api/human-resource-report/payrollreport',
      Staff_Report: 'api/human-resource-report/staff-report',
   }
   public static Attendnace_Report: any = {
      Attendance_Report: 'api/attendance-report/classattendencereport',
      Staff_Attendance_Report: 'api/attendance-report/staffattendancereport',
      Stduent_Attendance_Type_Report: 'api/attendance-report/studentattendancereport'
   }
   public static Download_Center: any = {
      Upload_Content: 'api/content'
   }

   public static Finanace_Report: any = {
      Expense_Report: 'api/finance-report/expense',
      Expense_Group_Report: 'api/finance-report/expensegroup',
      Fees_Statement_Report: 'api/finance-report/fee-statement',
      Income_Report: 'api/finance-report/income',
      Income_Group_Report: 'api/finance-report/incomegroup',
      Student_Academic_Report: 'api/finance-report/student-academic-report',
   }
   public static Dashboard: any = {
      Enquiry_Count: 'api/dashboard/get-enquiry-count',
      Libaray_Book_Count: 'api/dashboard/get-library-book-count',
      Student_Fees_Count: 'api/dashboard/get-student-fee-count',
      Todays_Attendance_count: 'api/dashboard/get-today-attendance-count',

   }


}