import { ComplaintService } from './../../../../../core/front-office/_services/complaint.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ComplainsDataSource, ComplaintModel } from '../../../../../core/front-office';
import { QueryParamsModel, LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription, merge, fromEvent, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../core/_base/layout';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { tap, debounceTime, distinctUntilChanged, skip, delay, take } from 'rxjs/operators';
import { ComplainEditDialogComponent } from '../complain-edit/complain-edit.dialog.component';
import { ComplainViewDialogComponent } from '../complain-view/complain-view.dialog.component';

@Component({
  selector: 'kt-complain-list',
  templateUrl: './complain-list.component.html',
  styleUrls: ['./complain-list.component.scss']
})
export class ComplainListComponent implements OnInit {

  // Table fields
  dataSource: ComplainsDataSource;
  displayedColumns = ['id', 'complaintType', 'name', 'contact', 'date', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterCondition = '';
  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<ComplaintModel>(true, []);
  complainsResult: ComplaintModel[] = [];
  private subscriptions: Subscription[] = [];


  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private complaintService: ComplaintService) { }


  /**
   * On init
   */
  ngOnInit() {

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
    - when a pagination event occurs => this.paginator.page
    - when a sort event occurs => this.sort.sortChange
    **/
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadComplainsList())
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadComplainsList();
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new ComplainsDataSource(this.complaintService);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.complainsResult = res;
      console.log(this.complainsResult);
      if (this.complainsResult.length == 0) this.dataSource.hasItems = false;
    });
    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadComplainsList();
    }); // Remove this line, just loading imitation
  }


  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load Products List
   */
  loadComplainsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server
    this.dataSource.loadPageRequesed(queryParams);
    // get api 
    this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterStatus && this.filterStatus.length > 0) {
      filter.status = +this.filterStatus;
    }

    if (this.filterCondition && this.filterCondition.length > 0) {
      filter.condition = +this.filterCondition;
    }

    filter.model = searchText;

    filter.manufacture = searchText;
    filter.color = searchText;
    filter.VINCode = searchText;
    // return filter;
    return searchText;
  }

  /**
   * Restore state
   *
   * @param queryParams: QueryParamsModel
   * @param id: number
   */
  restoreState(queryParams: QueryParamsModel, id: number) {

    if (!queryParams.filter) {
      return;
    }

    if ('condition' in queryParams.filter) {
      this.filterCondition = queryParams.filter.condition.toString();
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status.toString();
    }

    if (queryParams.filter.model) {
      this.searchInput.nativeElement.value = queryParams.filter.model;
    }
  }

  /** ACTIONS */
  /**
   * Delete product
   *
   * @param _item: ComplaintModel
   */
  deleteComplain(_item: ComplaintModel) {
    const _title = 'Complain Delete';
    const _description = 'Are you sure to permanently delete this complain ?';
    const _waitDesciption = 'Complain  is deleting...';
    const _deleteMessage = `Complain  has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      //delete api call
      // this.store.dispatch(new OneComplaintDeleted({ id: _item.id }));
      this.complaintService.deleteComplaint(_item.id).subscribe(res => {
        this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        this.loadComplainsList();
      }, err => {
        this.loadComplainsList();
        this.layoutUtilsService.showActionNotification("Error to delete complain !", MessageType.Failed)
      });


      // this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
    });
  }


  // Show add customer dialog

  addComplain() {
    const newCustomer = new ComplaintModel();
    newCustomer.clear(); // Set all defaults fields
    this.editComplain(newCustomer);
  }

  // Show Edit customer dialog and save after success close result

  editComplain(complain: ComplaintModel) {
    let saveMessageTranslateParam = 'ECOMMERCE.CUSTOMERS.EDIT.';
    const _saveMessage = complain.id > 0 ? 'Edit complain' : 'Create complain';

    const _messageType = complain.id > 0 ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(ComplainEditDialogComponent, { data: { complain } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }

      this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
      this.loadComplainsList();
    });
  }

  viewComplain(complain: ComplaintModel) {

    const dialogRef = this.dialog.open(ComplainViewDialogComponent, { data: { complain } });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }    
      // this.layoutUtilsService.showActionNotification(_saveMessage, _messageType);
      // this.loadComplainsList();
    });
  }
  downloadFile(uploadContent: ComplaintModel) {
    window.open(uploadContent.image, '_blank')
  }
  /**
   * Check all rows are selected
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.complainsResult.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection
   */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.complainsResult.forEach(row => this.selection.select(row));
    }
  }

  /* UI */
  /**
   * Returns status string
   *
   * @param status: number
   */
  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Selling';
      case 1:
        return 'Sold';
    }
    return '';
  }

  /**
   * Returns CSS Class by status
   *
   * @param status: number
   */
  getItemCssClassByStatus(status: number = 0): string {
    switch (status) {
      case 0:
        return 'success';
      case 1:
        return 'metal';
    }
    return '';
  }

  /**
   * Rerurns condition string
   *
   * @param condition: number
   */
  getItemConditionString(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'New';
      case 1:
        return 'Used';
    }
    return '';
  }

  /**
   * Returns CSS Class by condition
   *
   * @param condition: number
   */
  getItemCssClassByCondition(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'danger';
      case 1:
        return 'primary';
    }
    return '';
  }
}

