<kt-portlet>
	<!-- PORTLET LOADING | Binded to TABLE Datasource -->
	<!-- See prop => '~/core/_crud/models/data-sources/_base.datasource.ts' (loading$) -->
	<kt-portlet-header [title]="'Roles list'" [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$">

		<ng-container ktPortletTools>
			<button (click)="addRole()" mat-raised-button matTooltip="Create new role" color="primary" type="button">
				<span>New Role</span>
			</button>
			<!-- Buttons (Material Angular) | See off.documenations 'https://material.angular.io/components/button/overview' -->
			<!-- mat-raised-button | Rectangular contained button w/ elevation  -->
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form kt-margin-b-30">
			<!-- start::FILTERS -->
			<div class="form-filtration">
				<div class="row align-items-center">
					<div class="col-md-2 kt-margin-bottom-10-mobile">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput placeholder="Search user" #searchInput placeholder="Search">
							<mat-hint align="start">
								<strong>Search</strong> in all fields
							</mat-hint>
						</mat-form-field>
					</div>
				</div>
			</div>
			<!-- end::FILTERS -->

			<!-- start::GROUP ACTIONS -->
			<!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
			<!-- Group actions are shared for all LISTS -->
			<div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}">
				<!-- We show 'Group Actions' div if smth are selected -->
				<div class="col-xl-12">
					<div class="form-group form-group-inline">
						<div class="form-label form-label-no-wrap">
							<label class="font-bold font-danger">
								<span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
								{{ selection.selected.length }}
							</label>
							<!-- selectedCountsTitle => function from codeBehind (roles-list.component.ts file) -->
							<!-- selectedCountsTitle => just returns title of selected items count -->
							<!-- for example: Selected records count: 4 -->
						</div>
						<div>
							<button (click)="fetchRoles()" mat-raised-button matTooltip="Fetch selected roles"
								class="mat-button-mt-4">
								<mat-icon>clear_all</mat-icon>
								Fetch Selected
							</button>
						</div>
					</div>
				</div>
			</div>
			<!-- end::GROUP ACTIONS -->
		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div class="mat-table__wrapper">
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="id" matSortDirection="desc" matSortDisableClear>
				<!-- Checkbox Column -->

				<!-- Table with selection -->
				<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
				<ng-container matColumnDef="select">
					<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">
						<mat-checkbox (change)="$event ? masterToggle() : null"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *matCellDef="let row" class="mat-column-checkbox">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="id">
					<!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
					<mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
					<mat-cell *matCellDef="let role">{{role.id}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="title">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
					<mat-cell *matCellDef="let role">{{role.title}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
					<mat-cell *matCellDef="let role">
						<button mat-icon-button color="primary" matTooltip="{{ role.isCoreRole ? 'View' : 'Edit'}} role"
							(click)="editRole(role)">
							<mat-icon>
								{{ role.isCoreRole ? 'visibility' : 'create' }}
							</mat-icon>
						</button>&nbsp;
						<button mat-icon-button color="warn" matTooltip="Delete role" type="button"
							[disabled]="role.isCoreRole" (click)="deleteRole(role)">
							<mat-icon>delete</mat-icon>
						</button>
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>

			<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
			<!-- Message for empty data  -->
			<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table__bottom">
			<!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<!-- MATERIAL PAGINATOR | Binded to dasources -->
			<!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>