import { AdmissionEnquiryService } from './../_services/admission-enquiry.service';
// import { selectProductsInitWaitingMessage } from './../_selectors/product.selectors';
// RxJS
import { Observable, of } from "rxjs";
import 'rxjs/add/observable/of';
import { catchError, delay, distinctUntilChanged, finalize } from 'rxjs/operators';
// CRUD
import { QueryResultsModel, BaseDataSource, HttpExtenstionsModel } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
import { EnquiryModel } from '../_models/enquiry.model';
// Selectors

export class EnquirysDataSource extends BaseDataSource {
  constructor(private enquiryService:AdmissionEnquiryService) {
    super();
    // this.loading$ = this.store.pipe(
    //   select(selectAdmissionEnquirysPageLoading)
    // );

    // this.isPreloadTextViewed$ = this.store.pipe(
    //   select(selectAdmissionEnquirysShowInitWaitingMessage)
    // );

    // this.store.pipe(
    //   select(selectAdmissionEnquirysInStore)
    // ).subscribe((response: QueryResultsModel) => {
    //   this.paginatorTotalSubject.next(response.totalCount);
    //   this.entitySubject.next(response.items);
    // });
  }
  loadPageRequesed(page) {
    this.loading$ = Observable.of(true);
    this.isPreloadTextViewed$ = Observable.of(true);
    this.enquiryService.findAdmissionEnquirys(page).pipe(
      catchError(() => of([])),
      finalize(() => this.isPreloadTextViewed$ = this.loading$ = Observable.of(false),)
    ).subscribe(response => {
      this.loading$ = Observable.of(false);
      this.isPreloadTextViewed$ = Observable.of(false);
      const data = response['data'];
      this.paginatorTotalSubject.next(data.totalElements);
      this.entitySubject.next(data['content']);


      // const httpExtension = new HttpExtenstionsModel();
      // const result: EnquiryModel[] =
      //   httpExtension.sortArray(items, assignVehiclesState.lastQuery.sortField, assignVehiclesState.lastQuery.sortOrder);
      // return new QueryResultsModel(result, assignVehiclesState.totalCount, '');

    })
  }
}
