<kt-portlet>
	<kt-portlet-header [sticky]="true" [title]="'Phone Call List'" [class]="'card-head-lg'"
		[viewLoading$]="dataSource.loading$">
		<ng-container ktPortletTools>
			<button (click)="addPhoneCall()" mat-raised-button color="primary" matTooltip="Create new phoneCall">New
				Phone Call</button>
		</ng-container>
	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
			<div class="form-filtration">
					<div class="col-md-4 kt-margin-bottom-10-mobile">
						<mat-form-field>
							<input matInput placeholder="Search product" #searchInput placeholder="Search"
								class="mat-form-field mat-form-field-fluid">
							<mat-hint align="start">
								<strong>Search in all fields</strong>
							</mat-hint>
						</mat-form-field>
					</div>
				</div>
			</div>
    

			<div class="mat-table-wrapper">
				<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
					matSortActive="id" matSortDirection="desc" matSortDisableClear>
					
				<ng-container matColumnDef="id">
					<mat-header-cell class="w-25" *matHeaderCellDef  >Id
					</mat-header-cell>
					<mat-cell *matCellDef="let enquiry;let i=index">{{i+1}}</mat-cell>
				</ng-container>


				<ng-container matColumnDef="name">
					<mat-header-cell *matHeaderCellDef mat-sort-header >Name
					</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall" >{{phoneCall.name}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="contact">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall">{{phoneCall.contact}}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="date">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall">{{phoneCall.date | date:'dd-MM-yyyy'}}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="followUpDate">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Next Follow Up Date</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall">{{phoneCall.followUpDate | date:'dd-MM-yyyy'}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="callDuration">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Call Dureation</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall">{{phoneCall.callDureation}}</mat-cell>
				</ng-container>
		
				<ng-container matColumnDef="callType">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Call Type</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall">{{phoneCall.callType}}</mat-cell>
				</ng-container>



				<ng-container matColumnDef="actions">
					<mat-header-cell *matHeaderCellDef>
						Actions
					</mat-header-cell>
					<mat-cell *matCellDef="let phoneCall">

				        		<button type="button"(click)="viewPhoneCall(phoneCall)" mat-icon-button color="primary"
						matTooltip="View Details">
						<mat-icon>menu</mat-icon>
					</button>&nbsp;

					<!-- <ng-container *ngIf="phoneCall.image!=''">
						<button mat-icon-button color="primary" matTooltip="Download"
						(click)="downloadFile(phoneCall)">
						<mat-icon>get_app</mat-icon>
					  </button>&nbsp;
					</ng-container> -->
					

						<button type="button" (click)="editPhoneCall(phoneCall)" mat-icon-button color="primary"
							matTooltip="Edit Phone Call">
							<mat-icon>create</mat-icon>
						</button>&nbsp;
						<button mat-icon-button color="warn" type="button" (click)="deletePhoneCall(phoneCall)"
							matTooltip="Delete Phone Call">
							<mat-icon>delete</mat-icon>
						</button>&nbsp;
						
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>