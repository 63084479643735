<div class="card card-custom">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">Fetch selected elements</h3>
		</div>
	</div>
	<div class="form">
		<div class="card-body">
			<div class="timeline timeline-5 mt-3">
				<div class="timeline-item align-items-start" *ngFor="let message of data">
					<div class="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3"></div>
					<div class="timeline-badge">
						<i class="fa fa-genderless text-{{getItemCssClassByStatus(message.status)}} icon-xxl"></i>
					</div>
					<div class="timeline-content text-dark-50 mr-5">{{message.text}}
						<span
							class="label label-lg label-light-{{getItemCssClassByStatus(message.status)}} label-inline">ID:
							{{message.id}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button mat-button mat-raised-button (click)="onNoClick()">Cancel</button>&nbsp;
					<button mat-button mat-raised-button color="primary" [mat-dialog-close]="data.animal"
						cdkFocusInitial>Ok</button>&nbsp;
				</div>
			</div>
		</div>
	</div>
</div>