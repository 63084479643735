<kt-portlet>
	<kt-portlet-header [title]="getComponentTitle()" [class]="'card-head-lg'" [viewLoading$]="loading$">
		<ng-container ktPortletTools>
			<a [routerLink]="['../../']" class="btn btn-secondary mr-2" mat-raised-button matTooltip="Back to the users list">
				<i class="la la-arrow-left"></i>
				<span class="hidden-mobile">Back</span>
			</a>
			<a href="javascript:;" class="btn btn-secondary mr-2" (click)="reset()" [disabled]="selectedTab !== 0" mat-raised-button matTooltip="Reset changes">
				<i class="la la-cog"></i>
				<span class="hidden-mobile">Reset</span>
			</a>
			<a href="javascript:;" class="btn btn-primary mr-2" color="primary" (click)="onSumbit(false)" mat-raised-button matTooltip="Save & Continue">
				<span class="hidden-mobile">Save</span>
			</a>
			<button mat-icon-button [matMenuTriggerFor]="menu" color="primary">
				<mat-icon>more_vert</mat-icon>
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item color="primary" (click)="onSumbit(true)">Save & Exit</button>
				<button mat-menu-item color="primary">Save & Duplicate</button>
				<button mat-menu-item color="primary" (click)="onSumbit(false)">Save & Continue</button>
			</mat-menu>
		</ng-container>
	</kt-portlet-header>

	<kt-portlet-body>
		<mat-tab-group [(selectedIndex)]="selectedTab">
			<mat-tab>
				<ng-template mat-tab-label>
					<i class="mat-tab-label-icon fa fa-user"></i>
					Basic info
				</ng-template>
				<ng-template matTabContent>
					<!--begin::Form-->
					<div *ngIf="user">
						<form [formGroup]="userForm" class="form form-group-seperator-dashed">

							<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
								Oh snap! Fill the requird field and try submitting again.
							</kt-alert>
							<div class="form-section form-section-first">
								<div class="form-group row">
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Username" formControlName="username"/>
											<mat-error>Username is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Username</strong>
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Full Name" formControlName="fullname"/>
											<mat-error>Full Name is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Full Name</strong>
											</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input type="email" matInput placeholder="Enter Email" formControlName="email"/>
											<mat-error>Email is
												<strong>required</strong>
											</mat-error>
											<mat-hint align="start">Please enter
												<strong>Email</strong>
											</mat-hint>
										</mat-form-field>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Occupation" formControlName="occupation"/>
											<mat-hint align="start">Please enter
												<strong>Occupation</strong>
											</mat-hint>
										</mat-form-field>
									</div>
									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Company Name" formControlName="companyName"/>
											<mat-hint align="start">Please enter
												<strong>Company Name</strong>
											</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-lg-4 kt-margin-bottom-20-mobile">
										<mat-form-field class="mat-form-field-fluid">
											<input matInput placeholder="Enter Phone" formControlName="phone"/>
											<mat-hint align="start">Please enter
												<strong>Phone</strong>
											</mat-hint>
										</mat-form-field>
									</div>
								</div>
							</div>
						</form>
					</div>
					<!--end::Form-->
				</ng-template>
			</mat-tab>
			<mat-tab [disabled]="!user || !user.id">
				<ng-template mat-tab-label>
					<i class="mat-tab-label-icon fa fa-address-book"></i>
					User address
				</ng-template>
				<ng-template matTabContent>
					<kt-address [(addressSubject)]="addressSubject"></kt-address>
				</ng-template>
			</mat-tab>
			<mat-tab [disabled]="!user || !user.id">
				<ng-template mat-tab-label>
					<i class="mat-tab-label-icon fab fa-facebook"></i>
					Social Nerworks
				</ng-template>
				<ng-template matTabContent>
					<kt-social-networks [(socialNetworksSubject)]="soicialNetworksSubject"></kt-social-networks>
				</ng-template>
			</mat-tab>
			<mat-tab *ngIf="user" [disabled]="!user || !user.id">
				<ng-template mat-tab-label>
					<i class="mat-tab-label-icon fa fa-unlock"></i>
					User roles
				</ng-template>
				<ng-template matTabContent>
					<kt-user-roles-list [(rolesSubject)]="rolesSubject"></kt-user-roles-list>
				</ng-template>
			</mat-tab>
			<mat-tab *ngIf="user" [disabled]="!user || !user.id">
				<ng-template mat-tab-label>
					<i class="mat-tab-label-icon fa fa-exchange-alt"></i>
					Change password
				</ng-template>
				<ng-template matTabContent>
					<kt-change-password [userId]="user.id"></kt-change-password>
				</ng-template>
			</mat-tab>
		</mat-tab-group>
	</kt-portlet-body>
</kt-portlet>
