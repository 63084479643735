import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Constants } from '../api_url';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class UploadFileS3BucketService {

  constructor(private _httpClient: HttpClient, private uploadf: UploadFileS3BucketService) { }
  FOLDER = '';
  data: any;
  // https://school-erp-1.s3.ap-south-1.amazonaws.com/download+(2).jpg
  profileImgPath = ' https://school-erp-1.s3.ap-south-1.amazonaws.com/';

  bucket = new S3(
    {
      accessKeyId: 'AKIARPKH7WOA5KMZLBWI',
      secretAccessKey: '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',
      region: 'ap-south-1'
    }
  );

  uploadfile(file, folderName): any {

    this.FOLDER = folderName;
    debugger;

    return new Observable<any>((observer) => {

      if (file.length < 0) {
        return;
        // const params = {
        //   Bucket: 'school-erp-1/' + this.FOLDER,
        //   Key: file.name,
        //   Body: file,
        //   ACL: 'public-read',
        // };

        // // upload file
        // bucket.upload(params).on('httpUploadProgress', function (evt) {
        //   var uploaded = Math.round(evt.loaded / evt.total * 100);
        //   console.log(`File uploaded: ${uploaded}%`);
        // }).send(function (err, data) {
        //   if (err) {
        //     // an error occurred, handle the error
        //     console.log(err, err.stack);
        //     return;
        //   }

        //   var fileUrl = data.Location;
        //   console.log('File URL:', fileUrl);
        //   alert('File is uploaded successfully!');
        //   return data.Location;
        // })

      } else {



        for (var i = 0; i < file.length; i++) {
          // var count=1;
          const params = {
            Bucket: '271219-school/' + this.FOLDER,
            Key: file[i].name,
            Body: file[i],
            ACL: 'public-read',
          };


          // return  bucket.upload(params, function (err, data) {
          //     this.savefile = params.Body;
          //     if (err) {
          //       console.log('There was an error uploading your file: ', err);
          //       return false;
          //     } else {
          //       console.log('Successfully uploaded profile pic.', data);

          //       return true;
          //     }

          //   });

          //for upload progress   
          //   bucket.upload(params).on('httpUploadProgress', function (evt) {
          //     console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          //   }).send(function (err, data) {
          //     if (err) {
          //       console.log('There was an error uploading your file: ', err);
          //       return false;
          //     }
          //     this.data=data;
          //     // console.log('Successfully uploaded file.', data);
          //   //  console.log("path:::"+this.profileImgPath) ;

          //     return data;
          //     // return true;
          //   });


          // }
          // upload file

          this.bucket.upload(params).on('httpUploadProgress', function (evt) {
            var uploaded = Math.round(evt.loaded / evt.total * 100);
            console.log(`File uploaded: ${uploaded}%`);
          }).send(function (err, data) {
            if (err) {
              // count++;
              // console.log(err, err.stack);
              // return err;
              observer.error(err);
            }
            // console.log('File URL:', data);
            // count++;
            Constants.fileUrl = data;
            // if (count === file.length) {
            observer.next(data);
            observer.complete();
            // }

          });
        }
      }
    });
  }

  uploadOneFile(file, folderName) {
    return new Observable<any>((observer) => {
      const params = {
        Bucket: 'school-erp-1/' + folderName,
        Key: file.name,
        Body: file,
        ACL: 'public-read',
      };

      this.bucket.upload(params).on('httpUploadProgress', function (evt) {
        var uploaded = Math.round(evt.loaded / evt.total * 100);
        console.log(`File uploaded: ${uploaded}%`);
      }).send(function (err, data) {
        if (err) {
          // console.log(err, err.stack);
          // return err;
          observer.error(err);
        }
        // console.log('File URL:', data);
        Constants.fileUrl = data;
        observer.next(data);
        observer.complete();
      });
    });


  }

  removefile(key): any {

    return new Observable<any>((observer) => {
      const params = {
        Key: key,
        Bucket: 'school-erp-1/',

        // Body: file,
        // ACL: 'public-read',
      };
      this.bucket.deleteObject(params, function (err, data) {
        if (err) {
          console.log(err)
          observer.error(err);
          // observer.complete();
        } else {
          console.log(err)
          observer.next(data);
          observer.complete();
        }
      }).promise();
    });

  }







}