<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">Details</h3>
		</div>
	</div>
	
	<div class="card-body">

		<table class="table table-striped">    
			<tbody><tr>
				<th class="border0">To Title</th>
				<td class="border0">{{postalDispatch.toTitle}}</td>
				<th class="border0">Reference No</th>
				<td class="border0">{{postalDispatch.referenceNo}}</td>
			</tr>
			<tr>
				<th>Address</th>
				<td>{{postalDispatch.address}}</td>
				<th>Date</th>
				<td>{{postalDispatch.date | date:'dd-MM-yyyy'}}</td>
			</tr>
			<tr>
				<th>From Title</th>
				<td>{{postalDispatch.fromTitle}}</td>
				<th>Note</th>
				<td>{{postalDispatch.note}}</td>
			</tr>
			
		</tbody></table>
		
	</div>
	<div class="card-footer">
			
	</div>

</div>