import { Observable } from 'rxjs';
import { SessionModel } from './../../../core/sysetm_settings/_models/session.model';
import { SessionService } from 'src/app/core/sysetm_settings/_services/session.service';
import { HtmlClassService } from './../html-class.service';
import { LayoutConfigService } from './../../../core/_base/layout/services/layout-config.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SessionEditDialogComponent } from '../session-edit/session-edit.dialog.component';

@Component({
  selector: 'kt-session-brand',
  templateUrl: './session-brand.component.html',
  styleUrls: ['./session-brand.component.scss']
})
export class SessionBrandComponent implements OnInit {
  asideSelfMinimizeToggle = true;
  brandClasses = '';
  session: SessionModel;
  // session$: Observable<SessionModel>;

  constructor(private layoutConfigService: LayoutConfigService, public htmlClassService: HtmlClassService,
    public dialog: MatDialog,
    private sessionService: SessionService,) { }


  ngOnInit() {
    const sessionId = localStorage.getItem('sessionId');
    this.getsessionById(sessionId);
    this.brandClasses = this.htmlClassService.getClasses('brand', true).toString();
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle');
  }
  editSession() {
    let sessionData = this.session;
    const dialogRef = this.dialog.open(SessionEditDialogComponent, { data: { sessionData }, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      this.session = res.session;
      localStorage.setItem('sessionId', res.session.id.toString());
      if (!res) {
        return;
      }

    });
  }


  getsessionById(sessionId) {
    this.sessionService.getSessionById(sessionId)
      .subscribe(res => {
        console.log(res);

        this.session = res['data'];
      
        localStorage.setItem('session', this.session.session);
      });
  }


}
