<div class="card card-custom" [ngClass]="{ 'card-body-progress card-body-progress-overlay' : viewLoading }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">Details</h3>
		</div>
	</div>
	<div class="card-body">

		<table class="table table-striped">    
			<tbody><tr>
				<th class="border0">Purpose</th>
				<td class="border0">{{visitorsBook.purpose}}</td>
				<th class="border0">Name</th>
				<td class="border0">{{visitorsBook.name}}</td>
			</tr>
			<tr>
				<th>Phone</th>
				<td>{{visitorsBook.contact}}</td>
				<th>Number Of Person</th>
				<td>{{visitorsBook.noOfPepple}}</td>
			</tr>
			<tr>
				<th>Date</th>
				<td>{{visitorsBook.date| date:'dd-MM-yyyy'}}</td>
				<th>In Time</th>
				<td>{{visitorsBook.inTime}}</td>
			</tr>
			<tr>
				<th>Out Time</th>
				<td>{{visitorsBook.outTime}}</td>
				<th>Note</th>
				<td>{{visitorsBook.note}}</td>
			</tr>
		</tbody></table>
		
	</div>
	<div class="card-footer">
			
	</div>
</div>