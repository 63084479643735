import { AdmissionEnquiryFollowupDialogComponent } from './../admission-enquiry-followup/admission-enquiry-followup.dialog.component';
import { AdmissionEnquiryService } from './../../../../../core/front-office/_services/admission-enquiry.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EnquirysDataSource, EnquiryModel, } from '../../../../../core/front-office';
import { QueryParamsModel, LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription, merge, fromEvent, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SubheaderService } from '../../../../../core/_base/layout';

import { tap, debounceTime, distinctUntilChanged, skip, delay, take } from 'rxjs/operators';
import { AdmissionEnquiryEditDialogComponent } from '../admission-enquiry-edit/admission-enquiry-edit.dialog.component';

@Component({
  selector: 'kt-admission-enquiry-list',
  templateUrl: './admission-enquiry-list.component.html',
  styleUrls: ['./admission-enquiry-list.component.scss']
})
export class AdmissionEnquiryListComponent implements OnInit {
  // Table fields
  dataSource: EnquirysDataSource;
  displayedColumns = ['id', 'name', 'contact', 'source', 'date', 'followUpDate', 'status', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  filterStatus = '';
  filterCondition = '';
  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<EnquiryModel>(true, []);
  enquirysResult: EnquiryModel[] = [];
  private subscriptions: Subscription[] = [];


  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private enquiryService: AdmissionEnquiryService) { }


  /**
   * On init
   */
  ngOnInit() {

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
      tap(() => this.loadAddmissionEnquirysList())
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // this.dataSource.sort = this.sort;

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(50), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadAddmissionEnquirysList();
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    // Init DataSource
    this.dataSource = new EnquirysDataSource(this.enquiryService);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      skip(1),
      distinctUntilChanged()
    ).subscribe(res => {
      this.enquirysResult = res;
      console.log(this.enquirysResult);
      if (this.enquirysResult.length == 0) this.dataSource.hasItems = false;
    });
    this.subscriptions.push(entitiesSubscription);
    // First load
    of(undefined).pipe(take(1), delay(1000)).subscribe(() => { // Remove this line, just loading imitation
      this.loadAddmissionEnquirysList();
    });
  }


  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  /**
   * Load Products List
   */
  loadAddmissionEnquirysList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    // Call request from server

    this.dataSource.loadPageRequesed(queryParams);
    this.selection.clear();
  }

  /**
   * Returns object for filter
   */
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;

    if (this.filterStatus && this.filterStatus.length > 0) {
      filter.status = +this.filterStatus;
    }

    if (this.filterCondition && this.filterCondition.length > 0) {
      filter.condition = +this.filterCondition;
    }

    filter.model = searchText;

    filter.manufacture = searchText;
    filter.color = searchText;
    filter.VINCode = searchText;
    // return filter;
    return searchText;
  }


  restoreState(queryParams: QueryParamsModel, id: number) {

    if (!queryParams.filter) {
      return;
    }

    if ('condition' in queryParams.filter) {
      this.filterCondition = queryParams.filter.condition.toString();
    }

    if ('status' in queryParams.filter) {
      this.filterStatus = queryParams.filter.status.toString();
    }

    if (queryParams.filter.model) {
      this.searchInput.nativeElement.value = queryParams.filter.model;
    }
  }

  // Delete product

  deleteEnquiry(_item: EnquiryModel) {
    const _title = 'Admission Enquiry Delete';
    const _description = 'Are you sure to permanently delete this admission enquiry?';
    const _waitDesciption = 'Admission Enquiry is deleting...';
    const _deleteMessage = `Admission Enquiry has been deleted`;

    const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      //delete api call
      this.enquiryService.deleteAdmissionEnquiry(_item.id).subscribe(res => {
        this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        this.loadAddmissionEnquirysList();
      }, err => {
        this.loadAddmissionEnquirysList();
        this.layoutUtilsService.showActionNotification("Error to delete  admission enquiry", MessageType.Delete);
      });

    });
  }




  addAddmissionEnquiry() {
    const newCustomer = new EnquiryModel();
    newCustomer.clear(); // Set all defaults fields
    this.editAddmissionEnquiry(newCustomer);
  }


  editAddmissionEnquiry(enquiry: EnquiryModel) {

    const dialogRef = this.dialog.open(AdmissionEnquiryEditDialogComponent, { data: { enquiry }, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.loadAddmissionEnquirysList();
    });
  }



  followupAddmissionEnquiry(enquiry: EnquiryModel) {

    const dialogRef = this.dialog.open(AdmissionEnquiryFollowupDialogComponent, { data: { enquiry }, disableClose: true });
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.loadAddmissionEnquirysList();
    });
  }



  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.enquirysResult.length;
    return numSelected === numRows;
  }


  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.enquirysResult.forEach(row => this.selection.select(row));
    }
  }


  getItemStatusString(status: String): string {
    switch (status) {
      case 'active':
        return 'Active';
      case 'dead':
        return 'Dead';
      case 'passive':
        return 'Passive';
      case 'won':
        return 'Won';
      case 'lost':
        return 'Lost';


    }
    return '';
  }


  getItemCssClassByStatus(status: String): string {
    switch (status) {
      case 'active':
        return 'success';
      case 'dead':
        return 'warning';
      // case 'passive':
      //   return 'metal';
  case 'passive':
        return 'metal';
      case 'won':
        return 'primary';
      case 'lost':
        return 'danger';

      //         "white":      $white,
      // "primary":    $primary,
      //   "secondary":  $dark,
      //   "success":    $success,
      //   "info":       $info,
      //   "warning":    $warning,
      //   "danger":     $danger,
      //   "light":      $light,
      //   "dark":       $dark,
      //   "dark-75":    $dark-75,
      //   "dark-65":    $dark-65,
      //   "dark-50":    $dark-50,
      //   "dark-25":    $dark-25,
      //   "muted":      $text-muted 
    

    }
    return '';
  }


  getItemConditionString(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'New';
      case 1:
        return 'Used';
    }
    return '';
  }


  getItemCssClassByCondition(condition: number = 0): string {
    switch (condition) {
      case 0:
        return 'danger';
      case 1:
        return 'primary';
    }
    return '';
  }
}

