import { SessionModel } from './../../../core/sysetm_settings/_models/session.model';
import { SessionService } from 'src/app/core/sysetm_settings/_services/session.service';

import { LayoutUtilsService, MessageType, QueryParamsModel } from 'src/app/core/_base/crud';
import { AdmissionEnquiryService } from '../../../core/front-office/_services/admission-enquiry.service';
// Angular
import { Component, OnInit, Inject, ChangeDetectionStrategy, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Material
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// RxJS
import { Subscription, of } from 'rxjs';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnquiryModel, ReferenceModel, SourceModel, ReferenceService } from '../../../core/front-office';


@Component({
	// tslint:disable-next-line:component-selector
	selector: 'kt-session-edit-dialog',
	templateUrl: './session-edit.dialog.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
	encapsulation: ViewEncapsulation.None
})
export class SessionEditDialogComponent implements OnInit, OnDestroy {

	// Public properties
	session: SessionModel;
	sessionForm: FormGroup;
	hasFormErrors = false;
	viewLoading = false;
	// Private properties
	private componentSubscriptions: Subscription;

	sessionList: SessionModel[] = [];

	updatedSession;
	constructor(public dialogRef: MatDialogRef<SessionEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private enquiryService: AdmissionEnquiryService,
		private layoutUtilsService: LayoutUtilsService,
		private sessionService: SessionService,
	) {
	}

	/**
	 * On init
	 */
	ngOnInit() {

		//All Get Call
		this.loadAllSessions();
		this.session = this.data.sessionData;
		this.createForm();
	}

	//get All Complain Type List
	//all staff 

	loadAllSessions() {
		debugger
		this.sessionService.findAllSessions().subscribe(res => {
			// const data = res['data'];
			this.sessionList = res['data'];
			// this.sessionList = data['content'];
		}, err => {
		});
	}

	onSessionSelectChange(sessionId) {
		// this.sessionList.map(item => {
		// 	if (item.id === sessionId) {

		// 		this.updatedSession = item;
		// 		this.updatedSession.isActive = 'Yes';
		// 	}
		// })
	}
	createForm() {
		this.sessionForm = this.fb.group({

			sessionId: [this.session.id, Validators.required],

		});
	}
	/**
	 * On destroy
	 */
	ngOnDestroy() {
		if (this.componentSubscriptions) {
			this.componentSubscriptions.unsubscribe();
		}
	}



	/**
	 * Returns page title
	 */
	getTitle(): string {
		if (this.session.id > 0) {
			return `Edit Session '${this.session.session}'`;
		}

		return 'New Session';
	}

	/**
	 * Check control is invalid
	 * @param controlName: string
	 */
	isControlInvalid(controlName: string): boolean {
		const control = this.sessionForm.controls[controlName];
		const result = control.invalid && control.touched;
		return result;
	}

	/** ACTIONS */

	/**
	 * Returns prepared enquiry
	 */
	// prepareenquiry(): SessionModel {
	// 	const controls = this.sessionForm.controls;
	// 	const _session = new SessionModel();
	// 	_session.session = controls.session.value;
	// 	_session.id = controls.session.value;


	// 	if (_session.id > 0) {
	// 		_session.isActive = controls.isActive.value
	// 	} else {
	// 		_session.isActive = 'yes'
	// 	}




	// 	return _session;
	// }

	/**
	 * On Submit
	 */
	onSubmit() {
		debugger
		this.hasFormErrors = false;
		const controls = this.sessionForm.controls;
		/** check form */
		if (this.sessionForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);

			this.hasFormErrors = true;
			return;
		}

		this.changeSession(controls.sessionId.value);




	}

	/**
	 * Update enquiry
	 *
	 * @param _enquiry: EnquiryModel
	 */
	changeSession(sessionId: SessionModel) {
		this.sessionService.changeSession(sessionId).subscribe(res => {
			let session = res['data']
			console.log(res)
			this.layoutUtilsService.showActionNotification('Session has been updated', MessageType.Update);
			this.dialogRef.close({ session, isEdit: true });
		}, err => {
			this.layoutUtilsService.showActionNotification('Error to update Session', MessageType.Failed);
			this.dialogRef.close({ sessionId, isEdit: true });
		})



	}


	/** Alect Close event */
	onAlertClose($event) {
		this.hasFormErrors = false;
	}


}

