<div class="form-group kt-form__group row">
	<div class="col-lg-4 kt-margin-bottom-20-mobile">
		<kt-portlet>
			<!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
			<kt-portlet-header [class]="'kt-portlet__head--lg'" [title]="'Complaint Type'">


			</kt-portlet-header>
			<!-- end::Header -->

			<kt-portlet-body>
				<div class="kt-form kt-margin-b-30">
					<form class="kt-form" [formGroup]="complaintTypeForm">
						<div class="kt-portlet__body">

							<!-- <div class="kt-portlet__body-progress">
										<mat-spinner [diameter]="20"></mat-spinner>
									</div> -->

							<kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true"
								(close)="onAlertClose($event)">
								Oh snap! Fill the requird field and try submitting again.
							</kt-alert>

							<div class="form-group kt-form__group row">
								<div class="col-lg-12 kt-margin-bottom-20-mobile">
									<mat-form-field class="mat-form-field-fluid">
										<input matInput placeholder="Enter Complaint Type"
											formControlName="complaintType" />
										<mat-error>Complaint Type is
											<strong>required</strong>
										</mat-error>

									</mat-form-field>
								</div>
								<div class="col-lg-12 kt-margin-bottom-20-mobile">
									<mat-form-field class="mat-form-field-fluid">
										<textarea matInput rows="2" placeholder="Enter Description"
											formControlName="description"></textarea>
									</mat-form-field>
								</div>
							</div>
							<div class="row text-right">
								<div class="col-lg-12">

									<button type="button" mat-raised-button cdkFocusInitial matTooltip="Cancel changes"
										(click)="onCancel()">
										Cancel
									</button>&nbsp;
									<button type="button" mat-raised-button color="primary" (click)="onSubmit()"
										[disabled]="viewLoading" matTooltip="Save changes">
										Save
									</button>
								</div>
							</div>

						</div>

					</form>

					<div class="kt-separator kt-separator--dashed"></div>




				</div>
			</kt-portlet-body>
			<!-- end::Body -->

		</kt-portlet>

	</div>
	<div class="col-lg-8 kt-margin-bottom-20-mobile">
		<kt-portlet>
			<!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
			<kt-portlet-header [class]="'kt-portlet__head--lg'" [title]="'Complaint Type List'"
				[viewLoading$]="dataSource.loading$">


			</kt-portlet-header>
			<!-- end::Header -->


			<kt-portlet-body>

				<!-- start::FILTERS -->
				<div class="kt-form__filtration">
					<div class="row align-items-center">

						<div class="col-md-2 kt-margin-bottom-10-mobile">
							<mat-form-field class="mat-form-field-fluid">
								<input matInput placeholder="Search Check Out Policy " #searchInput
									placeholder="Search">
								<mat-hint align="start">
									<strong>Search</strong> in all fields
								</mat-hint>
							</mat-form-field>
						</div>

					</div>
				</div>
				<!-- end::FILTERS -->
				<div class="mat-table__wrapper">
					<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
						matSortActive="id" matSortDirection="desc" matSortDisableClear>
						<ng-container matColumnDef="id">
							<mat-header-cell *matHeaderCellDef >ID</mat-header-cell>
							<mat-cell *matCellDef="let complaintType; let i=index">{{i+1}}</mat-cell>
						</ng-container>

						<ng-container matColumnDef="complaintType">
							<!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
							<mat-header-cell *matHeaderCellDef mat-sort-header>Complaint Type</mat-header-cell>
							<mat-cell *matCellDef="let complaintType">{{complaintType.complaintType}}</mat-cell>
						</ng-container>

						<ng-container matColumnDef="description">
							<mat-header-cell class="w-75" *matHeaderCellDef mat-sort-header>Description
							</mat-header-cell>
							<mat-cell *matCellDef="let complaintType">{{complaintType.description}}</mat-cell>
						</ng-container>

						<ng-container matColumnDef="actions">
							<mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
							<mat-cell *matCellDef="let complaintType">
								
									<button mat-icon-button color="primary" matTooltip="Edit Complaint Type"
										(click)="editComplaintType(complaintType)">
										<mat-icon>create</mat-icon>
									</button>&nbsp;
								

									<button mat-icon-button color="warn" matTooltip="Delete Complaint Type"
										type="button" (click)="deleteComplaintType(complaintType)">
										<mat-icon>delete</mat-icon>
									</button>&nbsp;
								


							</mat-cell>
						</ng-container>

						<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

						<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
					</mat-table>
					<!-- Message for empty data  -->
					<div class="mat-table__message" *ngIf="!dataSource.hasItems">No records found</div>
					<div class="mat-table__message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....
					</div>
				</div>

				<!-- start: BOTTOM -->
				<div class="mat-table__bottom">
					<!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
					<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
					<!-- MATERIAL PAGINATOR | Binded to dasources -->
					<!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
					<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 20, 30]"
						[length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
				</div>
				<!-- end: BOTTOM -->

			</kt-portlet-body>
			<!-- end::Body -->

		</kt-portlet>
	</div>
</div>